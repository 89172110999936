import { Constantes } from '../../utils/Constantes';

export class User {
  ID: number;
  Key: string;
  Name: string;
  Title: string;
  Email: string;
  PictureUrl: string;

  constructor() {
    this.ID = 0;
    this.Key = "";
    this.Name = "";
    this.Title = "";
    this.Email = "";
    this.PictureUrl = "";
  }

  public static parseJsonList(elements: any[]): User[] {
    let lista: User[] = [];

    if (elements) {
      lista = elements.map(element => {
        const user = new User();
        if (user) {
          user.ID = element[Constantes.columnas.ID];
          user.Title = element[Constantes.columnas.Title];
          user.Email = element[Constantes.columnas.EMail];
        }

        return user;
      });
    }

    return lista;
  }

  public static parseJson(element: any): User {
    const user = new User();
    if (element) {
      user.ID = element[Constantes.columnas.ID];
      user.Name = element[Constantes.columnas.Title];
      user.Title = element[Constantes.columnas.Title];
      user.Email = element[Constantes.columnas.EMail];
    }

    return user;
  }

  public static parseJsonSPUser(element: any): User {
    const user = new User();
    if (element) {
      user.ID = element[Constantes.columnas.Id];
      user.Name = element[Constantes.columnas.Title];
      user.Title = element[Constantes.columnas.Title];
      user.Email = element[Constantes.columnas.EMail];
    }

    return user;
  }

  public static getJsonList(usuarios: User[]): any {
    if (!usuarios) {
      return [];
    }
    return { results: usuarios.map(usuario => usuario.ID) };
  }
}
