import { DatePipe } from '@angular/common';
import { Router, UrlSerializer } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RequestSerializerService {

  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private datePipe: DatePipe
  ) { }

  public ObtenerFormDataDesdeObjecto(object: Object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property) || !(object as any)[property]) {
        continue;
      }
      const formKey = namespace
        ? ((object as any)[property] instanceof File ? namespace :`${namespace}[${property}]`)
        : property;
      if ((object as any)[property] instanceof Date) {
        formData.append(formKey, (object as any)[property].toISOString());
      } else if (typeof (object as any)[property] === 'object' && !((object as any)[property] instanceof File)) {
        this.ObtenerFormDataDesdeObjecto((object as any)[property], formData, formKey);
      } else {
        formData.append(formKey, (object as any)[property]);
      }
    }
    return formData;
  }

  public ObtenerQueryStringDesdeObjeto(request: any): string {
    request = this.TransformarFechaStandar(request);
    const tree = this.router.createUrlTree([], { queryParams: request });
    const params = this.serializer.serialize(tree);
    const split = params.split('?');
    if (split.length <= 1) {
      return '';
    }
    const parameters = split[split.length - 1];
    return parameters;
  }

  private TransformarFechaStandar(object: any): any {
    if (object instanceof Date) {
      return this.datePipe.transform(object, 'yyyy-MM-ddTHH:mm:ss');
    }
    else if (typeof object === 'object') {
      for (let key in object) {
        var value = object[key];
        object[key] = this.TransformarFechaStandar(value);
      }
    }
    return object;
  }
}
