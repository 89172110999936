import { Constantes } from '../../utils/Constantes';
import { SPParse } from '../../utils/SPParse';

export class Lookup {
  ID: string;
  Title: string;
  TitleEN: string;

  constructor() {
    this.ID = "0";
    this.Title = "";
    this.TitleEN = "";
  }

  public static parseJson(element: any): Lookup {
    const objeto = new Lookup();

    if (element) {
      objeto.ID = element[Constantes.columnas.ID].toString();
      objeto.Title = element[Constantes.columnas.Title];
    }

    return objeto;
  }

  public static parseJsonBack(element: any): Lookup {
    const objeto = new Lookup();

    if (element) {
      objeto.ID = element["id"].toString();
      objeto.Title = element["title"];
      objeto.TitleEN = SPParse.getString(element["tituloEN"]);
    }

    return objeto;
  }

  public static parseJsonList(elements: any): Lookup[] {
    const listaLookup: Lookup[] = [];

    if (elements.length > 0) {
      for (const element of elements) {
        const objeto = new Lookup();
        objeto.ID = element[Constantes.columnas.ID].toString();
        objeto.Title = element[Constantes.columnas.Title];
        listaLookup.push(objeto);
      }
    }

    return listaLookup;
  }
}
