import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor() { }

    decodeHTML: Function = (function () {
        let element = document.createElement('div');

        function decodeHTMLEntities(str: any) {
            if (str && typeof str === 'string') {
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }
            return str;
        }

        return decodeHTMLEntities;
    })();

    loadLfFiles(attachments: Array<any>, attachmentsAPI: any): void {
        for (let attachment of attachments) {
            let extension: string, url: string, type: string;
            extension = attachment.FileName.split('.').pop();
            type = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"].includes(extension) ? "office" : (extension === "pdf") ? "application/pdf" : "other";

            if (type === "office") {
                url = `${environment.proxyUrl}${environment.webRelativeUrl}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(attachment.ServerRelativeUrl)}&action=embedview`
            } else {
                url = attachment.ServerRelativeUrl
            }

            attachmentsAPI.addRemoteFile(url, attachment.FileName, type);
        }
    }

    openLfFile(lfFile: any): void {
        if (lfFile.isRemote) {
            let url: string;

            if (lfFile.lfTagType === "office") {
                url = lfFile.lfDataUrl + "&action=default";
            } else {
                url = lfFile.lfDataUrl;
            }

            var win = window.open(url, "_blank");
            if (win) {
                win.focus();
            } else {
                let link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                link.click();
            }
        }
    }

    esNullOUndefinedOEmpty(valor: any): boolean {
        return typeof valor === "undefined" || valor === null || valor === "";
    }

    getDateFechaHoraParaConsultaREST(date: Date, hora: string) {
        if (date != null) {
            const d = new Date(date);

            const dia = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
            const mes = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);

            const dformat = [d.getFullYear(), mes, dia].join("-") + "T" + [hora, "00", "00"].join(":");

            return dformat;
        }
        else {
            return null;
        }
    }

    Distinct(items: any, mapper: any) {
        if (!mapper) {
            mapper = (item: any) => item;
        }

        return items.map(mapper).reduce((acc: any, item: any) => {
            if (acc.indexOf(item) === -1) {
                acc.push(item);
            }
            return acc;
        }, []);
    }

    SoloEmail(valor: any): boolean {
        let esValido = false;

        if (!this.esNullOUndefinedOEmpty(valor)) {
          const pattern = new RegExp(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          esValido = pattern.test(valor.toString());
        }
        return esValido;
      }
}
