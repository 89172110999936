import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { I18NEXT_SERVICE, I18NextModule, I18NextLoadResult, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nextOptions = {
  debug: true,
  fallbackLng: 'es',
  resources: {
    en: {
      translation: {
        "Nuevo_TituloFormulario": "VIRTUAL DOCUMENTS FILING DESK",
        "Nuevo_DatosRemitente": "Sender data",
        "Nuevo_PersonaNatural": "Natural person",
        "Nuevo_PersonaJuridica": "Legal person",
        "Nuevo_Comunidad": "Community",
        "Nuevo_ComunidadNoEncontrada": "The community wasn't found on the search box",
        "Nuevo_NombreComunidad": "Community's name",
        "Nuevo_DireccionComunidad": "Community's adress",
        "Nuevo_TipoDocumento": "Document type",
        "Nuevo_NumeroDocumento": "Document number",
        "Nuevo_Nombres": "First Name",
        "Nuevo_BotonValidar": "Validate",
        "Nuevo_ApellidoPaterno": "Last Name",
        "Nuevo_ApellidoMaterno": "Other Last Name",
        "Nuevo_Pais": "Country",
        "Nuevo_RUC": "RUC",
        "Nuevo_RazonSocial": "Business name",
        "Nuevo_DatosContacto": "Contact information",
        "Nuevo_PersonaContacto": "Contact person",
        "Nuevo_CorreoContacto": "Email",
        "Nuevo_CorreoContactoLocalidad": "Email where the charge will be sent",
        "Nuevo_TelefonoContacto": "Contact telephone",
        "Nuevo_Destinatario": "Addressee",
        "Nuevo_PersonaDestinataria": "Recipient or contact person",
        "Nuevo_Area": "Area",
        "Nuevo_Sede": "Campus",
        "Nuevo_DescripcionNoAplicaDestinatario": "Select here if it does not apply or you do not know the recipient. A person in charge of the parts table will refer to the corresponding area.",
        "Nuevo_DatosDocumento": "Document data",
        "Nuevo_DescripcionArchivoExtensiones": "Only files with the following extensions are allowed:",
        "Nuevo_DescripcionArchivoPesoMaximo": "The max. size per file is 50 MB",
        "Nuevo_Asunto": "Subject",
        "Nuevo_Referencia": "Reference",
        "Nuevo_BotonAgregarAjduntos": "Add Attachments",
        "Nuevo_Principal": "Principal",
        "Nuevo_Archivo": "Archive",
        "Nuevo_Codigo": "Code",
        "Nuevo_Tipo": "Type",
        "Nuevo_TituloDescripcion": "Title or description",
        "Nuevo_DescripcionNoSeAgregaronAdjuntos": "No attachments have been added.",
        "Nuevo_CheckAutorizacionTratamientoDatos": "I authorize the treatment of my personal data to TGP in order to register my request",
        "Nuevo_BotonEnviar": "Send",
        "Nuevo_RucInvalido": "Your information could not be validated, please register your information to continue with the document registration",
        'Nuevo_AdjuntoNoPdf': 'The file selected as the main one is not in PDF format, it is recommended that it be in PDF format so that the system can insert the "Virtual Charge" in said file, which will be sent to the email of the contact entered',
        'Nuevo_Gerencia': 'Senior management',
        'Nuevo_Seleccione': '-- SELECT ONE --',
        "Nuevo_Zona": "Zone",
        "Nuevo_Localidad": "Location",
        "Nuevo_Anexo": "Exhibit",
        "Nuevo_DatosComunidad": "Community information",
        "Nuevo_DetalleRadioComunidad": "If you are a community representative of if you belong to a TGP operations influence area, choose this option!",
        "Nuevo_IdentidadDocumentoRepresentante": "I'm going to register a document as a community representative",
        "Nuevo_IdentidadDocumentoParticular": "I'm going to register a document as a private person",
        "Nuevo_FormaEnvioCargo": "How do you want the document receipt charge sent to you?",
        "Nuevo_Correo": "Email",
        "Nuevo_Celular": "Phone (WhatsApp)",
        "Nuevo_Fisicamente": "Physically",
        "Nuevo_Notas": "Notes / Comments",
        "Nuevo_NotasLabel": "Notes / Comments",
        "Nuevo_InfoHorarioMPV": "The TGP Parts Desk is always available. Reception hours are 8 a.m. to 5 p.m, Monday to Friday, except holidays and/or non-working days. Documents uploaded outside of these hours will be considered received from the date the receipt confirmation email is sent.",

        "Exito_Texto1": "The file with number has been sent correctly",
        "Exito_Texto2": "You will receive confirmation of receipt in the email address entered in the registration.",
        "Exito_TextoWhatsapp": "You will receive confirmation of receipt in the Whatsapp entered in the registration.",
        "Exito_TextoFisico": "You will receive confirmation of receipt in the address entered in the registration.",
      }
    },
    es: {
      translation: {
        "Nuevo_FormaEnvioCargo": "¿De qué forma desea que se le envíe el cargo de recepción del documento?",
        "Nuevo_IdentidadDocumentoRepresentante": "Voy a registrar un documento como representante de la comunidad",
        "Nuevo_IdentidadDocumentoParticular": "Voy a registrar un documento como persona particular",
        "Nuevo_DetalleRadioComunidad": "Si eres representante de una comunidad o eres una persona del área de influencia de las operaciones de TGP, ¡selecciona esta opción!",
        "Nuevo_TituloFormulario": "MESA DE PARTES VIRTUAL",
        "Nuevo_DatosRemitente": "Datos del Remitente",
        "Nuevo_PersonaNatural": "Persona Natural",
        "Nuevo_PersonaJuridica": "Persona Jurídica",
        "Nuevo_Comunidad": "Comunidad",
        "Nuevo_ComunidadNoEncontrada": "No se encuentra la comunidad en el buscador",
        "Nuevo_NombreComunidad": "Nombre de la comunidad",
        "Nuevo_DireccionComunidad": "Dirección de la comunidad",
        "Nuevo_TipoDocumento": "Tipo de documento",
        "Nuevo_NumeroDocumento": "Número de documento",
        "Nuevo_Nombres": "Nombres",
        "Nuevo_BotonValidar": "Validar",
        "Nuevo_ApellidoPaterno": "Apellido paterno",
        "Nuevo_ApellidoMaterno": "Apellido materno",
        "Nuevo_Pais": "País",
        "Nuevo_RUC": "RUC",
        "Nuevo_RazonSocial": "Razón social",
        "Nuevo_Zona": "Zona",
        "Nuevo_Localidad": "Localidad",
        "Nuevo_Anexo": "Anexo",
        "Nuevo_DatosContacto": "Datos de contacto",
        "Nuevo_PersonaContacto": "Persona de contacto",
        "Nuevo_CorreoContacto": "Correo electrónico",
        "Nuevo_CorreoContactoLocalidad": "Correo electrónico a donde se enviará el cargo",
        "Nuevo_TelefonoContacto": "Teléfono de contacto",
        "Nuevo_DireccionContacto": "Direccion de contacto",
        "Nuevo_Destinatario": "Destinatario",
        "Nuevo_PersonaDestinataria": "Persona destinataria o de contacto",
        "Nuevo_Area": "Área",
        "Nuevo_Sede": "Sede",
        "Nuevo_DescripcionNoAplicaDestinatario": "Seleccione aquí si no aplica o no conoce al destinatario. Un responsable de mesa de partes derivará al área que corresponda.",
        "Nuevo_DatosDocumento": "Datos del documento",
        "Nuevo_DescripcionArchivoExtensiones": "Sólo se permiten archivos con las siguientes extensiones:",
        "Nuevo_DescripcionArchivoPesoMaximo": "El peso máximo por archivo es de 50 MB",
        "Nuevo_Asunto": "Asunto",
        "Nuevo_Referencia": "Referencia",
        "Nuevo_BotonAgregarAjduntos": "Agregar Adjuntos",
        "Nuevo_Principal": "Principal",
        "Nuevo_Archivo": "Archivo",
        "Nuevo_Codigo": "Código",
        "Nuevo_Tipo": "Tipo",
        "Nuevo_TituloDescripcion": "Título o descripción",
        "Nuevo_DescripcionNoSeAgregaronAdjuntos": "No se han agregado adjuntos.",
        "Nuevo_CheckAutorizacionTratamientoDatos": "Autorizo el tratamiento de mis datos personales a TGP con la finalidad que pueda registrar mi solicitud",
        "Nuevo_BotonEnviar": "Enviar",
        "Nuevo_RucInvalido": "No se ha podido validar su información, favor de registrar sus datos para continuar con el registro del documento",
        'Nuevo_AdjuntoNoPdf': 'El archivo seleccionado como principal no es formato PDF, se recomienda que sea formato PDF para que el sistema pueda insertar el "Cargo Virtual" en dicho archivo, que será enviado al correo del contacto ingresado',
        'Nuevo_Gerencia': 'Gerencia senior',
        'Nuevo_Seleccione': '-- SELECCIONE --',
        "Nuevo_DatosComunidad": "Datos de la comunidad",
        "Nuevo_Correo": "Correo electrónico",
        "Nuevo_Celular": "Celular (WhatsApp)",
        "Nuevo_Fisicamente": "Físicamente",
        "Nuevo_Notas": "Notas y/o comentarios",
        "Nuevo_NotasLabel": "Notas / Comentarios",
        "Nuevo_InfoHorarioMPV": "La Mesa de Partes de TGP se encuentra siempre disponible. El horario de recepción es de 8 a.m. a 5 p.m., de lunes a viernes, exceptuando los días feriados y/o no laborables. Los documentos que se carguen fuera de ese horario se considerarán recibidos desde la fecha de envío del correo de confirmación de recepción.",

        "Exito_Texto1": "Se ha enviado correctamente el expediente con número",
        "Exito_Texto2": "Recibirá la confirmación de recepción en el correo electrónico consignado en el registro.",
        "Exito_TextoWhatsapp": "Recibirá la confirmación de recepción al Whatsapp consignado en el registro.",
        "Exito_TextoFisico": "Recibirá la confirmación de recepción a la dirección consignada en el registro.",
      }
    }
  },
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
  }
};

export function appInit(i18next: ITranslationService) {
  return () => {
    let promise: Promise<I18NextLoadResult> = i18next
      .use<any>(LanguageDetector)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18NextModule.forRoot()
  ],
  providers: [
    I18N_PROVIDERS
  ]
})
export class AppTranslationModule { }

