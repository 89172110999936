import { SessionStorageService } from './../../../shared/services/session-storage.service';
import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormularioBase } from 'src/app/shared/pages/FormularioBase';
import { MasterService } from 'src/app/shared/services/master.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'
import { AdministracionService } from 'src/app/shared/services/administracion.service';
import { ListItem } from 'src/app/shared/models/base/ListItem';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { EMaestro } from 'src/app/shared/models/entidades/EMaestro';
import { EParametrosGenerales } from 'src/app/shared/models/entidades/EParametrosGenerales';
import { ComunicacionService } from 'src/app/shared/services/comunicacionService.service';
import { MaestroDocumentoInternoService } from 'src/app/shared/services/maestro-documento-interno.service';
import { EUsuario } from 'src/app/shared/models/entidades/EUsuario';
import { Lookup } from 'src/app/shared/models/base/Lookup';
import { EComunicacionAdjuntoRegistrarDto } from 'src/app/shared/models/entidades/EComunicacionAdjuntoRegistrarDto';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { Funciones } from 'src/app/shared/utils/Funciones';
import { ERegistrarComunicacionExternaDto } from 'src/app/shared/models/request/ERegistrarComunicacionExternaDto';
import { Deferred } from 'ts-deferred';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ETipoDocumento } from 'src/app/shared/models/entidades/ETipoDocumento';
import { FormHelper } from 'src/app/shared/utils/form-helper';
import { NotasComponent } from '../notas/notas.component';

@Component({
  selector: 'app-nuevo-registro',
  templateUrl: './nuevo-registro.component.html',
  styleUrls: ['./nuevo-registro.component.css']
})
export class NuevoRegistroComponent extends FormularioBase implements OnInit {

  public ResetRecaptcha: boolean = false;
  public Token: string = environment.recaptchaSiteKey;
  public RecaptchaResolvedResponse!: string;
  public MostrarErrorRecaptcha: boolean = false;
  public IsCargando: boolean = true;
  public IsLoadingAutoComplete: boolean = false;
  public IsLoadingComunidad: boolean = false;
  public ItemsTipoDocumentoIdentidad: EMaestro[] = [];
  public ItemsTipoDocumento: EMaestro[] = [];
  public ItemsPaisRemitente: EMaestro[] = [];
  public ItemsComunidad: any[] = [];
  public ItemsGerenciaSenior: any[] = [];
  public ItemsFiltradosComunidad: any[] = [];
  public ItemsFiltradosDestinatario: EUsuario[] = [];
  public ItemsAdjuntos: EComunicacionAdjuntoRegistrarDto[] = [];
  public ItemsZona: any[] = [];
  public ItemsLocalidad: any[] = [];
  public ItemsAnexo: any[] = [];
  public ItemsComunidadProveedor: any[] = [];
  public ItemsComunidadProveedorFiltrados: any[] = [];

  public mostrarBloqueValidacionPersonaNatural: boolean = false;
  public mostrarBloqueValidacionPersonaComunidad: boolean = false;
  public mostrarCamposPN: boolean = true;
  public mostrarCamposPJ: boolean = true;
  public mostrarCamposCo: boolean = false;
  public mostrarRucInvalido: boolean = false;
  public mostrarRucInvalidoNatural: boolean = false;
  public mostrarRucInvalidoJuridica: boolean = false;
  public mostrarRucInvalidoComunidad: boolean = false;
  public mostrarAjuntoNoPdf: boolean = false;

  public longitudTipoDocumentoIdentidad: number = 0;
  public longitudPersonaJuridica: number = 0;
  public numeroDocumentoPersonaJuridica = '';
  public tipoDocumentoPersonaJuridica: number = 0;
  public nombreTipoDocumentoPersonaJuridica = '';
  public abreviaturaEmpresa = '';
  public idEmpresa = 0;
  public idPais = 0;
  public nombreEmpresa = '';
  public urlLogoEmpresa = '';
  public disclaimer: string = '';
  public autorizacionDatos: string = '';
  public muestraSeccionDatosCominidadMVP: boolean = false;
  public textoMesaPartesComunidad: string = '';

  urlLogo: string = "";
  language: string = 'es';
  languageFlag: string = 'peru-bandera';

  public campo: any = {
    idRemitenteExterno: 0,
    destinatarioBuscar: "",
    destinatario: new EUsuario(),
    destinatariocompleto: "",
    area: new Lookup(),
    sede: new Lookup(),
    puesto: new Lookup()
  }

  public ItemJuridica: EMaestro = new EMaestro();

  nombreControles = {
    tipoRemitente: 'tipoRemitente',
    tipoDocumentoRemitente: 'tipoDocumentoRemitente',
    tipoDocumentoRemitenteNatural: 'tipoDocumentoRemitenteNatural',
    tipoDocumentoRemitenteComunidad: 'tipoDocumentoRemitenteComunidad',
    numeroDocumentoRemitente: 'numeroDocumentoRemitente',
    numeroDocumentoRemitenteNatural: 'numeroDocumentoRemitenteNatural',
    numeroDocumentoRemitenteComunidad: 'numeroDocumentoRemitenteComunidad',
    nombresRemitente: 'nombresRemitente',
    nombresRemitenteNatural: 'nombresRemitenteNatural',
    nombresRemitenteComunidad: 'nombresRemitenteComunidad',
    apellidoPaternoRemitente: 'apellidoPaternoRemitente',
    apellidoPaternoRemitenteNatural: 'apellidoPaternoRemitenteNatural',
    apellidoPaternoRemitenteComunidad: 'apellidoPaternoRemitenteComunidad',
    apellidoMaternoRemitente: 'apellidoMaternoRemitente',
    apellidoMaternoRemitenteNatural: 'apellidoMaternoRemitenteNatural',
    apellidoMaternoRemitenteComunidad: 'apellidoMaternoRemitenteComunidad',
    paisRemitente: 'paisRemitente',
    rucRemitente: 'rucRemitente',
    razonSocialRemitente: 'razonSocialRemitente',
    contactoRemitente: 'contactoRemitente',
    contactoRemitenteNatural: 'contactoRemitenteNatural',
    contactoRemitenteJuridica: 'contactoRemitenteJuridica',
    contactoRemitenteComunidad: 'contactoRemitenteComunidad',
    correoElectronicoRemitente: 'correoElectronicoRemitente',
    correoElectronicoRemitenteNatural: 'correoElectronicoRemitenteNatural',
    correoElectronicoRemitenteJuridica: 'correoElectronicoRemitenteJuridica',
    correoElectronicoRemitenteComunidad: 'correoElectronicoRemitenteComunidad',
    telefonoContactoRemitente: 'telefonoContactoRemitente',
    telefonoContactoRemitenteNatural: 'telefonoContactoRemitenteNatural',
    telefonoContactoRemitenteJuridica: 'telefonoContactoRemitenteJuridica',
    telefonoContactoRemitenteComunidad: 'telefonoContactoRemitenteComunidad',
    direccionContactoRemitenteComunidad: 'direccionContactoRemitenteComunidad',

    destinatario: 'destinatario',
    area: 'area',
    sede: 'sede',

    asunto: 'asunto',
    referencia: 'referencia',

    noAplicaDestinatario: 'noAplicaDestinatario',
    autorizacion: 'autorizacion'
  };

  private _notasComponent: NotasComponent | undefined;
  @ViewChild('notas') set notasComponent(value: NotasComponent) {
    this._notasComponent = value;
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public utilService: UtilService,
    public administracionService: AdministracionService,
    public masterService: MasterService,
    public formBuilder: FormBuilder,
    public comunicacionService: ComunicacionService,
    public maestroDocumentoInternoService: MaestroDocumentoInternoService,
    private toastr: ToastrService,
    private sessionStorageService: SessionStorageService
  ) {
    super('nuevo-registro', dialog, route, router, spinner, utilService, masterService);

    this.form = this.formBuilder.group({
      tipoRemitente: ['Jurídica', Validators.required],
      identificadorDocumento: new FormControl(0, []),
      formaEnvioCargo: [null],
      tipoDocumentoRemitente: ['', Validators.required],
      tipoDocumentoRemitenteNatural: ['', Validators.required],
      tipoDocumentoRemitenteComunidad: ['', Validators.required],
      numeroDocumentoRemitente: ['', Validators.required],
      numeroDocumentoRemitenteNatural: ['', Validators.required],
      numeroDocumentoRemitenteComunidad: ['', Validators.required],
      nombresRemitente: ['', Validators.required],
      nombresRemitenteNatural: ['', Validators.required],
      nombresRemitenteComunidad: ['', Validators.required],
      apellidoPaternoRemitente: ['', Validators.required],
      apellidoPaternoRemitenteNatural: ['', Validators.required],
      apellidoPaternoRemitenteComunidad: ['', Validators.required],
      apellidoMaternoRemitente: ['', Validators.required],
      apellidoMaternoRemitenteNatural: ['', Validators.required],
      apellidoMaternoRemitenteComunidad: ['', Validators.required],
      paisRemitente: [''],
      rucRemitente: ['', Validators.required],
      razonSocialRemitente: ['', Validators.required],
      contactoRemitente: [''],
      contactoRemitenteNatural: [''],
      contactoRemitenteJuridica: [''],
      contactoRemitenteComunidad: ['', Validators.required],
      correoElectronicoRemitente: ['', Validators.email],
      correoElectronicoRemitenteNatural: ['', Validators.email],
      correoElectronicoRemitenteJuridica: ['', Validators.email],
      correoElectronicoRemitenteComunidad: ['', Validators.email],
      telefonoContactoRemitente: [''],
      telefonoContactoRemitenteNatural: [''],
      telefonoContactoRemitenteJuridica: [''],
      telefonoContactoRemitenteComunidad: ['', Validators.email],
      direccionContactoRemitente: [''],
      direccionContactoRemitenteComunidad: [''],

      destinatario: ['', Validators.required],
      area: ['', Validators.required],
      sede: ['', Validators.required],

      asunto: ['', Validators.required],
      referencia: [''],

      noAplicaDestinatario: true,
      autorizacion: false,

      busquedaComunidad: [null, Validators.required],
      idComunidad: [null, Validators.required],
      sinComunidad: [false],
      nombreComunidad: [null, Validators.required],
      direccionComunidad: [null, []],
      idGerenciaSenior: [null],

      idZona: [null],
      idLocalidad: [null],
      idAnexo: [null],
    });
  }

  ngOnInit(): void {
    this.InicializarControles();
  }

  InicializarControles() {

    this.mostrarProgreso();
    this.ObtenerData();
    this.onChangeNoAplicaDestinatario({ checked: true });
    this.onChangeTipoRemitente({ value: "Jurídica" });

    this.i18NextService.events.initialized.subscribe((e) => {
      if (e) {
        this.updateState(this.i18NextService.language);
      }
    });
  }

  obtenerParametro(parametro: string): any {
    const valor = this.route.snapshot.queryParams[parametro];

    if (!valor) {
      return 0;
    }

    return valor;
  }

  ObtenerData() {

    const lng = this.obtenerParametro('lng');
    if (lng == 0) {
      const url = window.location.origin + "/?lng=es";
      window.location.href = url;
    }

    const emp = this.obtenerParametro('emp');

    if (emp == 0) {
      const lngVal = this.obtenerParametro('lng');
      let textoError = '';

      if (lngVal === 'es') {
        textoError = 'El enlace de la pagina no cuenta con el identificador de la empresa';
      }
      else {
        textoError = 'The page link does not have the company identifier';
      }

      sessionStorage.setItem('SesionRegistroPublico_MensajeError', textoError);
      const url = window.location.origin + "/error";
      window.location.href = url;
    }

    this.sessionStorageService.IdEmpresa = parseInt(emp);

    Promise.all([
      this.maestroDocumentoInternoService.obtenerMaestros("AdmPais", ""),
      this.maestroDocumentoInternoService.filtrarMaestroTipoDocumentos(),
      this.maestroDocumentoInternoService.obtenerMaestros("AdmParametrosGenerales", "Tipo de documento"),
      this.maestroDocumentoInternoService.obtenerEmpresa(emp),
      this.maestroDocumentoInternoService.obtenerMaestros("AdmLocalidades", ""),
      this.maestroDocumentoInternoService.obtenerGerenciasSenior(),
      this.maestroDocumentoInternoService.obtenerMaestros("AdmZona", ""),
      this.administracionService.getItemsAdmProveedor('', 'Comunidad', emp),
      this.maestroDocumentoInternoService.obtenerConfiguracion('Configuracion mesa de partes'),
      //this.administracionService.obtenerUltimoId()
    ])
      .then(([
        elementosPais,
        elementosTipoDocumentoIdentidad,
        elementosTipoDocumento,
        elementoEmpresa,
        listaLocalidades,
        listaGerencias,
        listaZonas,
        listaComunidades,
        confMesaPartes,
        //ultimo
      ]) => {

        this.ItemsComunidad = listaLocalidades.data;
        this.ItemsGerenciaSenior = listaGerencias.data;
        this.ItemsComunidadProveedor = listaComunidades;
        this.ItemsZona = listaZonas.data;

        const lngVal = this.obtenerParametro('lng');

        this.textoMesaPartesComunidad = lngVal === 'es' ? confMesaPartes.data.valor : confMesaPartes.data.valorEn;
        if (elementoEmpresa.data.id === 0) {

          let textoError = '';

          if (lngVal === 'es') {
            textoError = 'El identificador de la empresa no es un dato valido';
          }
          else {
            textoError = 'The company identifier is not a valid data';
          }

          sessionStorage.setItem('SesionRegistroPublico_MensajeError', textoError);
          const url = window.location.origin + "/error";
          window.location.href = url;
        }
        else {
          this.idEmpresa = elementoEmpresa.data.id;
          this.idPais = elementoEmpresa.data.pais.id;
          this.nombreEmpresa = elementoEmpresa.data.title;
          this.urlLogoEmpresa = elementoEmpresa.data.urlLogo;
          this.urlLogo = elementoEmpresa.data.urlLogo;
          this.abreviaturaEmpresa = elementoEmpresa.data.abreviatura;
          this.disclaimer = lngVal === 'es'
            ? elementoEmpresa.data.disclaimerMesaPartes
            : elementoEmpresa.data.disclaimerMesaPartesEN;
          this.autorizacionDatos = lngVal === 'es'
            ? elementoEmpresa.data.autorizacionDatosPersonales
            : elementoEmpresa.data.autorizacionDatosPersonalesEN;
        }

        const elementoJuridica = elementosTipoDocumentoIdentidad.data.filter((elemento: any) => {
          return elemento.title === 'RUC';
        });

        this.ItemsTipoDocumento = EMaestro.parseJsonListBack(elementosTipoDocumento.data, lng);
        const ItemsTipoDocumentoIdentidad = EMaestro.parseJsonListBack(elementosTipoDocumentoIdentidad.data, lng);

        if (elementoJuridica.length !== 0) {
          this.ItemJuridica = EMaestro.parseJsonBack(elementoJuridica[0]);

          this.longitudPersonaJuridica = 0;
          this.nombreTipoDocumentoPersonaJuridica = this.ItemJuridica.Title;
          this.tipoDocumentoPersonaJuridica = parseInt(this.ItemJuridica.ID, 10);

          this.ItemsTipoDocumentoIdentidad = ItemsTipoDocumentoIdentidad.filter((elemento: EMaestro) => {
            return elemento.ID !== this.ItemJuridica.ID;
          });
        }

        this.ItemsPaisRemitente = EMaestro.parseJsonListBack(elementosPais.data, lng);

        //establecer peru
        const paisDefecto = this.ItemsPaisRemitente.find(x =>
          x.Title.toLowerCase() === 'perú' ||
          x.Title.toLowerCase() === 'peru');
        if (paisDefecto) {
          this.form.get(this.nombreControles.paisRemitente)?.setValue(paisDefecto.ID);
          this.eventoSeleccionarPais(paisDefecto);
        }

        this.ocultarProgreso();

      })
      .catch(error => {
        this.ocultarProgreso();
        console.dir('Error: ' + error);
      });

    this.form.controls[this.nombreControles.tipoDocumentoRemitente].disable();
    this.form.controls[this.nombreControles.numeroDocumentoRemitente].disable();
    this.form.controls[this.nombreControles.nombresRemitente].disable();
    this.form.controls[this.nombreControles.nombresRemitenteNatural].disable();
    this.form.controls[this.nombreControles.nombresRemitenteComunidad].disable();
    this.form.controls[this.nombreControles.apellidoPaternoRemitente].disable();
    this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].disable();
    this.form.controls[this.nombreControles.apellidoPaternoRemitenteComunidad].disable();
    this.form.controls[this.nombreControles.apellidoMaternoRemitente].disable();
    this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].disable();
    this.form.controls[this.nombreControles.apellidoMaternoRemitenteComunidad].disable();

    this.form.controls[this.nombreControles.paisRemitente].enable();
    this.form.controls[this.nombreControles.rucRemitente].enable();
    this.form.controls[this.nombreControles.razonSocialRemitente].disable();
  }

  get primaryEmail() {
    return this.form.get(this.nombreControles.correoElectronicoRemitente);
  }

  public cambioGerenciaSenior(): void {
    const idGerencia = this.form.get('idGerenciaSenior')?.value;
    this.form.get('idZona')?.setValue(null);
    this.form.get('idLocalidad')?.setValue(null);
    this.form.get('idAnexo')?.setValue(null);
    this.ItemsLocalidad = [];
    this.ItemsAnexo = [];
    if (idGerencia) {
      const item = this.ItemsGerenciaSenior.find(x => x.id === idGerencia);
      this.muestraSeccionDatosCominidadMVP = item.muestraSeccionDatosCominidadMVP;
    } else {
      this.muestraSeccionDatosCominidadMVP = false;
    }
  }

  public async cambioZona(): Promise<void> {
    const idZona = this.form.get('idZona')?.value;
    this.form.get('idLocalidad')?.setValue(null);
    this.form.get('idAnexo')?.setValue(null);
    if (idZona) {
      const listaLocalidad = await this.maestroDocumentoInternoService.obtenerLocalidadesPorZona(idZona);
      this.ItemsLocalidad = listaLocalidad.data;
    } else {
      this.ItemsLocalidad = [];
    }
  }

  public async cambioLocalidad(): Promise<void> {
    const idLocalidad = this.form.get('idLocalidad')?.value;
    if (idLocalidad) {
      const listaAnexos = await this.maestroDocumentoInternoService.obtenerAnexosPorLocalidad(idLocalidad);
      this.ItemsAnexo = listaAnexos.data;
    } else {
      this.ItemsAnexo = [];
    }
  }

  onClickCambiarIdioma(idioma: string): void {
    const url = window.location.origin + "/?lng=" + idioma;
    window.location.href = url;
  }

  private establecerRequerido(nombre: string, requerido: boolean, correo?: boolean): void {
    this.form.controls[nombre].clearValidators();
    this.form.controls[nombre].updateValueAndValidity();
    const validators = [];
    if (requerido) {
      validators.push(Validators.required);
    }
    if (correo) {
      validators.push(Validators.email);
    }
    this.form.controls[nombre].setValidators(validators);
    this.form.controls[nombre].updateValueAndValidity();
  }

  onChangeTipoRemitente(event: any): void {
    const valor = event.value;//this.getValorControlString(this.nombreControles.tipoRemitente);
    this.form.get('identificadorDocumento')?.setValue(0);
    this.form.get('formaEnvioCargo')?.setValue(0);
    if (valor === "Natural") {
      this.mostrarBloqueValidacionPersonaNatural = true;
      this.mostrarBloqueValidacionPersonaComunidad = false;

      this.form.controls[this.nombreControles.tipoDocumentoRemitenteNatural].enable();
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteNatural].enable();
      this.form.controls[this.nombreControles.nombresRemitenteNatural].disable();
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].disable();
      this.form.controls['busquedaComunidad'].disable();
      this.form.controls['idComunidad'].disable();
      this.form.controls['nombreComunidad'].disable();
      this.form.controls['direccionComunidad'].disable();
      this.establecerRequerido(this.nombreControles.contactoRemitenteNatural, false);
      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteNatural, true, true);

      this.form.controls[this.nombreControles.paisRemitente]?.setValue('');
      this.form.controls[this.nombreControles.rucRemitente]?.setValue('');
      this.form.controls[this.nombreControles.razonSocialRemitente]?.setValue('');
      this.form.controls[this.nombreControles.paisRemitente].disable();
      this.form.controls[this.nombreControles.rucRemitente].disable();
      this.form.controls[this.nombreControles.razonSocialRemitente].disable();
      this.form.controls['busquedaComunidad'].setValue(null);
      this.form.controls['idComunidad'].setValue(null);
      this.form.controls['sinComunidad'].setValue(false);
      this.form.controls['nombreComunidad'].setValue(null);
      this.form.controls['direccionComunidad'].setValue(null);

      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteJuridica, false, false);
      this.establecerRequerido(this.nombreControles.contactoRemitenteComunidad, false, false);
      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteComunidad, false, false);

      /*this.form.controls[this.nombreControles.paisRemitente].updateValueAndValidity();
      this.form.controls[this.nombreControles.rucRemitente].updateValueAndValidity();
      this.form.controls[this.nombreControles.razonSocialRemitente].updateValueAndValidity();*/

      this.mostrarCamposPN = true;
      this.mostrarCamposPJ = false;
      this.mostrarCamposCo = false;
    } else if (valor === "Jurídica") {
      this.mostrarBloqueValidacionPersonaNatural = false;
      this.mostrarBloqueValidacionPersonaComunidad = false;

      this.form.controls[this.nombreControles.paisRemitente].enable();
      this.form.controls[this.nombreControles.rucRemitente].enable();
      this.form.controls[this.nombreControles.razonSocialRemitente].disable();
      this.form.controls['busquedaComunidad'].disable();
      this.form.controls['idComunidad'].disable();
      this.form.controls['sinComunidad'].disable();
      this.form.controls['nombreComunidad'].disable();
      this.form.controls['direccionComunidad'].disable();

      this.establecerRequerido(this.nombreControles.tipoDocumentoRemitenteNatural, false);
      this.establecerRequerido(this.nombreControles.numeroDocumentoRemitenteNatural, false);
      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteNatural, false, false);

      this.establecerRequerido('busquedaComunidad', false);
      this.establecerRequerido(this.nombreControles.contactoRemitenteComunidad, false);
      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteComunidad, false, false);

      this.establecerRequerido(this.nombreControles.contactoRemitenteJuridica, false);
      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteJuridica, true, true);

      this.form.controls[this.nombreControles.tipoDocumentoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.nombresRemitenteNatural].disable();
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].disable();

      this.form.controls[this.nombreControles.tipoDocumentoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.nombresRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteComunidad].disable();
      // this.form.controls[this.nombreControles.contactoRemitenteComunidad].disable();
      // this.form.controls[this.nombreControles.correoElectronicoRemitenteComunidad].disable();

      this.form.controls[this.nombreControles.tipoDocumentoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.nombresRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].setValue('');

      this.form.controls[this.nombreControles.tipoDocumentoRemitenteComunidad].setValue('');
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteComunidad].setValue('');
      this.form.controls[this.nombreControles.nombresRemitenteComunidad].setValue('');
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteComunidad].setValue('');
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteComunidad].setValue('');
      // this.form.controls[this.nombreControles.contactoRemitenteComunidad].setValue('');
      // this.form.controls[this.nombreControles.correoElectronicoRemitenteComunidad].setValue('');

      this.form.controls['busquedaComunidad'].setValue(null);
      this.form.controls['idComunidad'].setValue(null);
      this.form.controls['sinComunidad'].setValue(false);
      this.form.controls['nombreComunidad'].setValue(null);
      this.form.controls['direccionComunidad'].setValue(null);

      // this.establecerRequerido(this.nombreControles.tipoDocumentoRemitente, false, false)
      // this.establecerRequerido(this.nombreControles.numeroDocumentoRemitente, false, false)
      // this.establecerRequerido("busquedaComunidad", false, false)

      this.mostrarCamposPN = false;
      this.mostrarCamposPJ = true;
      this.mostrarCamposCo = false;
    } else if (valor === 'Localidad') {
      this.mostrarBloqueValidacionPersonaNatural = false;

      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteJuridica, false, false);

      this.form.controls[this.nombreControles.tipoDocumentoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.nombresRemitenteNatural].disable();
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].disable();
      this.form.controls[this.nombreControles.paisRemitente].disable();
      this.form.controls[this.nombreControles.rucRemitente].disable();

      this.form.controls['busquedaComunidad'].enable();
      this.form.controls['idComunidad'].enable();
      this.form.controls['sinComunidad'].enable();
      this.form.controls['nombreComunidad'].disable();
      this.form.controls['direccionComunidad'].disable();
      this.establecerRequerido(this.nombreControles.contactoRemitenteComunidad, true);
      this.establecerRequerido(this.nombreControles.correoElectronicoRemitenteComunidad, false, true);

      this.form.controls[this.nombreControles.rucRemitente].setValue('');
      this.form.controls[this.nombreControles.tipoDocumentoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.nombresRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].setValue('');
      this.form.controls[this.nombreControles.paisRemitente]?.setValue('');
      this.form.controls['busquedaComunidad'].setValue(null);
      this.form.controls['idComunidad'].setValue(null);
      this.form.controls['sinComunidad'].setValue(false);
      this.form.controls['nombreComunidad'].setValue(null);
      this.form.controls['direccionComunidad'].setValue(null);

      this.mostrarCamposPN = false;
      this.mostrarCamposPJ = false;
      this.mostrarCamposCo = true;

      this.cambioFormaEnvioCargo();
    }
  }

  public cambiarSeleccionMostrarDireccionComunidad(): void {
    const valor = this.form.controls['sinComunidad'].value;
    this.campo.idRemitenteExterno = 0;
    this.form.controls['busquedaComunidad'].setValue(null);
    this.form.controls['idComunidad'].setValue(null);
    if (valor) {
      this.form.controls['nombreComunidad'].enable();
      this.form.controls['direccionComunidad'].enable();
      this.form.controls['busquedaComunidad'].disable();
      this.form.controls['idComunidad'].disable();
    } else {
      this.form.controls['nombreComunidad'].disable();
      this.form.controls['direccionComunidad'].disable();
      this.form.controls['busquedaComunidad'].enable();
      this.form.controls['idComunidad'].enable();
    }
  }

  public eventoFiltrarComunidad(): void {
    const busqueda = this.form.controls['busquedaComunidad'].value as string;
    if (busqueda && busqueda.length >= 3) {
      this.IsLoadingComunidad = true;
      this.ItemsComunidadProveedorFiltrados = this.ItemsComunidadProveedor.filter(x => {
        const enTitulo = x.title.toLowerCase().indexOf(busqueda.toLowerCase()) !== -1;
        return enTitulo;
      });
      this.IsLoadingComunidad = false;
    }
  }

  public seleccionComunidad(item: any, event: any): void {
    if (!event.isUserInput) return;
    setTimeout(() => {
      this.form.controls['busquedaComunidad'].setValue(item.title);
      this.form.controls['idComunidad'].setValue(parseInt(item.id));
      this.form.controls['nombreComunidad'].setValue(item.title);
      this.form.controls['direccionComunidad'].setValue(item.direccion);
      this.campo.idRemitenteExterno = parseInt(item.id);
    }, 150);
  }

  eventoSeleccionarTipoDocumento(item: EMaestro): void {
    this.longitudTipoDocumentoIdentidad = item.Longitud;
    this.form.controls[this.nombreControles.numeroDocumentoRemitente].setValue('');
    this.form.controls[this.nombreControles.rucRemitente].setValue('');
  }

  eventoSeleccionarTipoDocumentoNatural(item: EMaestro): void {
    this.longitudTipoDocumentoIdentidad = item.Longitud;
    this.form.controls[this.nombreControles.numeroDocumentoRemitenteNatural].setValue('');
  }

  eventoSeleccionarTipoDocumentoComunidad(item: EMaestro): void {
    this.longitudTipoDocumentoIdentidad = item.Longitud;
    this.form.controls[this.nombreControles.numeroDocumentoRemitenteComunidad].setValue('');
  }

  eventoSeleccionarPais(item: EMaestro): void {

    this.form.controls[this.nombreControles.rucRemitente].setValue('');
    if (item.Title.toUpperCase() === "PERÚ" || item.Title.toUpperCase() === "PERU") {
      this.longitudPersonaJuridica = 11;
    } else {
      this.longitudPersonaJuridica = this.ItemJuridica.Longitud;
    }
  }

  onChangeNoAplicaDestinatario(event: any): void {
    const valor = event.checked;
    if (valor) {
      this.campo.destinatarioBuscar = "";
      this.campo.destinatario = new EUsuario();
      this.campo.destinatariocompleto = "";
      this.campo.sede = new Lookup();
      this.campo.area = new Lookup();
      this.campo.puesto = new Lookup();
      this.form.controls[this.nombreControles.destinatario].setValue('');
      this.form.controls[this.nombreControles.sede].setValue('');
      this.form.controls[this.nombreControles.area].setValue('');
      this.form.controls[this.nombreControles.destinatario].disable();
      this.form.controls[this.nombreControles.sede].disable();
      this.form.controls[this.nombreControles.area].disable();
    } else {
      this.form.controls[this.nombreControles.destinatario].enable();
      this.form.controls[this.nombreControles.sede].enable();
      this.form.controls[this.nombreControles.area].enable();
    }
  }

  onClickValidarRemitente(): void {
    const tipoPersona = this.getValorControlString(this.nombreControles.tipoRemitente);
    const datos: any = {};
    datos.TipoPersona = tipoPersona === 'Localidad' ? 'Natural' : tipoPersona;
    datos.IdPais = 1;
    datos.IdEmpresa = this.idEmpresa;

    let esPersonaNarutal = tipoPersona === 'Natural' || tipoPersona === 'Localidad';
    let faltanDatos = false;

    if (esPersonaNarutal) {
      datos.NumeroDocumento = this.getValorControlString(this.nombreControles.numeroDocumentoRemitente);
      datos.IdTipoDocumento = this.getValorControlNumber(this.nombreControles.tipoDocumentoRemitente);

      const elementoTipoDocumento = this.ItemsTipoDocumentoIdentidad.filter(x => x.ID === datos.IdTipoDocumento.toString());

      if (elementoTipoDocumento.length !== 0) {
        datos.TipoDocumento = elementoTipoDocumento[0].Title;
      }

      if (datos.IdTipoDocumento === 0) {
        this.toastr.warning(this.language === 'es' ? "Seleccione el tipo de documento" : "Select the type of document", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
        faltanDatos = true;
      }

      if (Funciones.esUndefinedNullOrEmpty(datos.NumeroDocumento)) {
        this.toastr.warning(this.language === 'es' ? "Ingrese el número de documento" : "Enter the document number", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
        faltanDatos = true;
      }
    }
    else {
      datos.NumeroDocumento = this.getValorControlString(this.nombreControles.rucRemitente);
      datos.IdTipoDocumento = this.tipoDocumentoPersonaJuridica;
      datos.TipoDocumento = this.nombreTipoDocumentoPersonaJuridica;
      //datos.IdPais = this.getValorControlNumber(this.nombreControles.paisRemitente);

      if (datos.IdPais === 0) {
        this.toastr.warning(this.language === 'es' ? "Seleccione el país" : "Select country", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
        faltanDatos = true;
      }

      if (Funciones.esUndefinedNullOrEmpty(datos.NumeroDocumento)) {
        this.toastr.warning(this.language === 'es' ? "Ingrese el número de documento" : "Enter the document number", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
        faltanDatos = true;
      }
    }

    if (faltanDatos) {
      return;
    }

    this.mostrarProgreso();

    this.maestroDocumentoInternoService.buscarRemitente(datos)
      .then((result: any) => {
        console.dir(result);

        this.ocultarProgreso();

        if (result.data.id !== 0) {
          this.form.controls[this.nombreControles.razonSocialRemitente].disable();
          this.mostrarRucInvalidoJuridica = false;
          this.campo.idRemitenteExterno = result.data.id;
          if (esPersonaNarutal) {
            this.form.get(this.nombreControles.nombresRemitente)?.setValue(result.data.nombres);
            this.form.get(this.nombreControles.apellidoPaternoRemitente)?.setValue(result.data.apellidoPaterno);
            this.form.get(this.nombreControles.apellidoMaternoRemitente)?.setValue(result.data.apellidoMaterno);
          }
          else {
            this.form.get(this.nombreControles.razonSocialRemitente)?.setValue(result.data.nombreEntidad);
          }
        } else {

          // this.toastr.warning('No se encontró un remitente con los datos ingresados', '¡Informativo!');
          //this.toastr.warning(this.language === 'es' ? "No se encontró un remitente con los datos ingresados" : "No sender found with the entered data", this.i18NextService.language === "es" ? "¡Informativo!" : "Informative!");
          this.mostrarRucInvalidoJuridica = true;

          this.campo.idRemitenteExterno = 0;
          if (esPersonaNarutal) {
            this.form.get(this.nombreControles.nombresRemitente)?.setValue('');
            this.form.get(this.nombreControles.apellidoPaternoRemitente)?.setValue('');
            this.form.get(this.nombreControles.apellidoMaternoRemitente)?.setValue('');

            this.form.controls[this.nombreControles.nombresRemitente].enable();
            this.form.controls[this.nombreControles.apellidoPaternoRemitente].enable();
            this.form.controls[this.nombreControles.apellidoMaternoRemitente].enable();
          }
          else {
            this.form.get(this.nombreControles.razonSocialRemitente)?.setValue('');
            this.form.controls[this.nombreControles.razonSocialRemitente].enable();
          }

        }
      })
      .catch((error: any) => {
        this.mostrarModalError('Error Buscar Remitente: ', error.message);
        this.ocultarProgreso();
      });
  }

  onClickValidarRemitenteNatural(): void {
    const tipoPersona = this.getValorControlString(this.nombreControles.tipoRemitente);
    const datos: any = {};
    datos.TipoPersona = tipoPersona;
    datos.IdPais = 1;
    datos.IdEmpresa = this.idEmpresa;

    let faltanDatos = false;

    datos.NumeroDocumento = this.getValorControlString(this.nombreControles.numeroDocumentoRemitenteNatural);
    datos.IdTipoDocumento = this.getValorControlNumber(this.nombreControles.tipoDocumentoRemitenteNatural);

    const elementoTipoDocumento = this.ItemsTipoDocumentoIdentidad.filter(x => x.ID === datos.IdTipoDocumento.toString());

    if (elementoTipoDocumento.length !== 0) {
      datos.TipoDocumento = elementoTipoDocumento[0].Title;
    }

    if (datos.IdTipoDocumento === 0) {
      this.toastr.warning(this.language === 'es' ? "Seleccione el tipo de documento" : "Select the type of document", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
      faltanDatos = true;
    }

    if (Funciones.esUndefinedNullOrEmpty(datos.NumeroDocumento)) {
      this.toastr.warning(this.language === 'es' ? "Ingrese el número de documento" : "Enter the document number", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
      faltanDatos = true;
    }

    if (faltanDatos) {
      return;
    }

    this.mostrarProgreso();

    this.maestroDocumentoInternoService.buscarRemitente(datos)
      .then((result: any) => {
        console.dir(result);

        this.ocultarProgreso();

        if (result.data.id !== 0) {
          this.form.controls[this.nombreControles.razonSocialRemitente].disable();
          this.mostrarRucInvalidoNatural = false;
          this.campo.idRemitenteExterno = result.data.id;

          this.form.get(this.nombreControles.nombresRemitenteNatural)?.setValue(result.data.nombres);
          this.form.get(this.nombreControles.apellidoPaternoRemitenteNatural)?.setValue(result.data.apellidoPaterno);
          this.form.get(this.nombreControles.apellidoMaternoRemitenteNatural)?.setValue(result.data.apellidoMaterno);

        } else {

          // this.toastr.warning('No se encontró un remitente con los datos ingresados', '¡Informativo!');
          //this.toastr.warning(this.language === 'es' ? "No se encontró un remitente con los datos ingresados" : "No sender found with the entered data", this.i18NextService.language === "es" ? "¡Informativo!" : "Informative!");
          this.mostrarRucInvalidoNatural = true;

          this.campo.idRemitenteExterno = 0;

          this.form.get(this.nombreControles.nombresRemitenteNatural)?.setValue('');
          this.form.get(this.nombreControles.apellidoPaternoRemitenteNatural)?.setValue('');
          this.form.get(this.nombreControles.apellidoMaternoRemitenteNatural)?.setValue('');

          this.form.controls[this.nombreControles.nombresRemitenteNatural].enable();
          this.form.controls[this.nombreControles.apellidoPaternoRemitenteNatural].enable();
          this.form.controls[this.nombreControles.apellidoMaternoRemitenteNatural].enable();

        }
      })
      .catch((error: any) => {
        this.mostrarModalError('Error Buscar Remitente: ', error.message);
        this.ocultarProgreso();
      });
  }

  onClickValidarRemitenteComunidad(): void {
    const tipoPersona = this.getValorControlString(this.nombreControles.tipoRemitente);
    const datos: any = {};
    datos.TipoPersona = tipoPersona;
    datos.IdPais = 1;
    datos.IdEmpresa = this.idEmpresa;

    let faltanDatos = false;

    datos.NumeroDocumento = this.getValorControlString(this.nombreControles.numeroDocumentoRemitenteComunidad);
    datos.IdTipoDocumento = this.getValorControlNumber(this.nombreControles.tipoDocumentoRemitenteComunidad);

    const elementoTipoDocumento = this.ItemsTipoDocumentoIdentidad.filter(x => x.ID === datos.IdTipoDocumento.toString());

    if (elementoTipoDocumento.length !== 0) {
      datos.TipoDocumento = elementoTipoDocumento[0].Title;
    }

    if (datos.IdTipoDocumento === 0) {
      this.toastr.warning(this.language === 'es' ? "Seleccione el tipo de documento" : "Select the type of document", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
      faltanDatos = true;
    }

    if (Funciones.esUndefinedNullOrEmpty(datos.NumeroDocumento)) {
      this.toastr.warning(this.language === 'es' ? "Ingrese el número de documento" : "Enter the document number", this.i18NextService.language === "es" ? "¡Validación!" : "Validation!");
      faltanDatos = true;
    }

    if (faltanDatos) {
      return;
    }

    this.mostrarProgreso();

    this.maestroDocumentoInternoService.buscarRemitente(datos)
      .then((result: any) => {
        console.dir(result);

        this.ocultarProgreso();

        if (result.data.id !== 0) {
          this.form.controls[this.nombreControles.razonSocialRemitente].disable();
          this.mostrarRucInvalidoComunidad = false;
          this.campo.idRemitenteExterno = result.data.id;

          this.form.get(this.nombreControles.nombresRemitenteComunidad)?.setValue(result.data.nombres);
          this.form.get(this.nombreControles.apellidoPaternoRemitenteComunidad)?.setValue(result.data.apellidoPaterno);
          this.form.get(this.nombreControles.apellidoMaternoRemitenteComunidad)?.setValue(result.data.apellidoMaterno);

        } else {

          // this.toastr.warning('No se encontró un remitente con los datos ingresados', '¡Informativo!');
          //this.toastr.warning(this.language === 'es' ? "No se encontró un remitente con los datos ingresados" : "No sender found with the entered data", this.i18NextService.language === "es" ? "¡Informativo!" : "Informative!");
          this.mostrarRucInvalidoComunidad = true;

          this.campo.idRemitenteExterno = 0;

          this.form.get(this.nombreControles.nombresRemitenteComunidad)?.setValue('');
          this.form.get(this.nombreControles.apellidoPaternoRemitenteComunidad)?.setValue('');
          this.form.get(this.nombreControles.apellidoMaternoRemitenteComunidad)?.setValue('');

          this.form.controls[this.nombreControles.nombresRemitenteComunidad].enable();
          this.form.controls[this.nombreControles.apellidoPaternoRemitenteComunidad].enable();
          this.form.controls[this.nombreControles.apellidoMaternoRemitenteComunidad].enable();

        }
      })
      .catch((error: any) => {
        this.mostrarModalError('Error Buscar Remitente: ', error.message);
        this.ocultarProgreso();
      });
  }

  async eventoFiltrarDestinatario() {
    this.IsLoadingAutoComplete = true;
    const itemsFiltradosDestinatario = [];

    const textoBusqueda = this.getValorControlString(this.nombreControles.destinatario);

    // const Items = await this.maestroDocumentoInternoService.filtrarMaestroUsuarios(this.campo.destinatarioBuscar);
    const Items = await this.maestroDocumentoInternoService.filtrarMaestroUsuarios(textoBusqueda);

    if (Items.data.length > 0) {
      for (const item of Items.data) {
        itemsFiltradosDestinatario.push(EUsuario.parseJsonBack(item));
      }
    }

    console.dir(this.ItemsFiltradosDestinatario);
    this.ItemsFiltradosDestinatario = itemsFiltradosDestinatario;

    this.IsLoadingAutoComplete = false;
  }

  eventoSeleccionarDestinatario(destinantario: EUsuario): void {

    this.campo.destinatarioBuscar = destinantario.Usuario.Name;
    this.campo.destinatariocompleto = destinantario;
    this.campo.destinatario = destinantario.Usuario;
    this.campo.sede = destinantario.Sede;
    this.campo.area = destinantario.Area;
    this.campo.puesto = destinantario.Puesto;

    const lng = this.obtenerParametro('lng');

    if (lng === "es") {
      this.form.get(this.nombreControles.sede)?.setValue(this.campo.sede.Title);
      this.form.get(this.nombreControles.area)?.setValue(this.campo.area.Title);
    } else {
      this.form.get(this.nombreControles.sede)?.setValue(this.campo.sede.TitleEN);
      this.form.get(this.nombreControles.area)?.setValue(this.campo.area.TitleEN);
    }
  }

  OnFileUploadDocumentosAdjuntos(event: any): void {
    if (event.target.files.length > 0) {

      const files = event.target.files;
      let listaArchivosRepetidos = [];

      for (let i = 0; i < files.length; i++) {
        const archivo = files[i];

        const archivoRepetido = this.ItemsAdjuntos.filter(d => d.NombreArchivo === archivo.name);

        if (archivoRepetido.length > 0) {
          listaArchivosRepetidos.push(archivo.name);
        }
      }

      if (listaArchivosRepetidos.length > 0) {
        const nombreArchivosRepetidos = listaArchivosRepetidos.join(', ');
        let mensaje = '';

        if (this.language === "en") {
          mensaje = 'Los siguientes archivos están repetidos: ' + nombreArchivosRepetidos;
        } else {
          mensaje = 'The following files are repeated: ' + nombreArchivosRepetidos;
        }

        this.mostrarModalInformativo('¡Validación!', mensaje);

        $('#FileControlDocumentosAdjuntos').val('');
      }
      else {
        for (let i = 0; i < files.length; i++) {

          this.convertirArrayByte(files[i]).then((resultByteArchivo: any) => {
            const byteArchivo: any = resultByteArchivo;

            const datosArchivo: EComunicacionAdjuntoRegistrarDto = {
              EsPrincipal: false,
              NombreArchivo: files[i].name,
              Codigo: '',
              Tipo: '0',
              Descripcion: '',
              Archivo: byteArchivo
            };

            this.ItemsAdjuntos.push(datosArchivo);
          })
        }

        $('#FileControlDocumentosAdjuntos').val('');
      }
    }
  }

  OnEliminarAdjunto(item: EComunicacionAdjuntoRegistrarDto): void {
    this.ItemsAdjuntos = this.ItemsAdjuntos.filter((elemento: EComunicacionAdjuntoRegistrarDto) => {
      return elemento.NombreArchivo !== item.NombreArchivo;
    });
  }

  onChangeAdjuntoEsPrincipal(item: EComunicacionAdjuntoRegistrarDto, event: any): void {

    for (let i = 0; i < this.ItemsAdjuntos.length; i++) {
      this.ItemsAdjuntos[i].EsPrincipal = false;

      if (this.ItemsAdjuntos[i].NombreArchivo === item.NombreArchivo) {
        this.ItemsAdjuntos[i].EsPrincipal = true;
        const nombre = this.ItemsAdjuntos[i].NombreArchivo;
        const split = nombre.split('.');
        const extension = split[split.length - 1];
        this.mostrarAjuntoNoPdf = extension.toLowerCase() !== 'pdf';
      }
    }
  }

  onChangeAdjuntoCodigo(item: EComunicacionAdjuntoRegistrarDto, event: any): void {

    for (let i = 0; i < this.ItemsAdjuntos.length; i++) {

      if (this.ItemsAdjuntos[i].NombreArchivo === item.NombreArchivo) {
        this.ItemsAdjuntos[i].Codigo = event.target.value;
      }
    }
  }

  onChangeAdjuntoTipo(item: EComunicacionAdjuntoRegistrarDto, event: any): void {

    for (let i = 0; i < this.ItemsAdjuntos.length; i++) {

      if (this.ItemsAdjuntos[i].NombreArchivo === item.NombreArchivo) {
        const elementoTipo = this.ItemsTipoDocumento.filter((elemento: EMaestro) => {
          return elemento.Title === event.target.innerText;
        });

        if (elementoTipo.length > 0) {
          this.ItemsAdjuntos[i].Tipo = elementoTipo[0].ID;
        }
      }
    }
  }

  onChangeAdjuntoDescripcion(item: EComunicacionAdjuntoRegistrarDto, event: any): void {

    for (let i = 0; i < this.ItemsAdjuntos.length; i++) {

      if (this.ItemsAdjuntos[i].NombreArchivo === item.NombreArchivo) {
        this.ItemsAdjuntos[i].Descripcion = event.target.value;
      }
    }
  }

  getJsonElementoComunicacion(): any {

    const datos: ERegistrarComunicacionExternaDto = new ERegistrarComunicacionExternaDto();

    datos.IdPais = this.idPais;
    datos.IdEmpresa = this.idEmpresa;
    datos.AbreviaturaEmpresa = this.abreviaturaEmpresa;

    const documentoPrincipal = this.ItemsAdjuntos.filter((elemento: EComunicacionAdjuntoRegistrarDto) => {
      return elemento.EsPrincipal;
    });

    datos.TipoComunicacion = 'E';
    datos.TipoRemitente = this.getValorControlString(this.nombreControles.tipoRemitente);
    datos.IdRemitenteExterno = this.campo.idRemitenteExterno;

    switch (datos.TipoRemitente) {
      case "Natural":
        datos.IdTipoDocumentoRemitente = this.getValorControlNumber(this.nombreControles.tipoDocumentoRemitenteNatural);
        datos.NumeroDocumentoRemitente = this.getValorControlString(this.nombreControles.numeroDocumentoRemitenteNatural);
        datos.NombreRemitente = this.getValorControlString(this.nombreControles.nombresRemitenteNatural);
        datos.ApellidoPaternoRemitente = this.getValorControlString(this.nombreControles.apellidoPaternoRemitenteNatural);
        datos.ApellidoMaternoRemitente = this.getValorControlString(this.nombreControles.apellidoMaternoRemitenteNatural);
        datos.NombrePersonaContacto = this.getValorControlString(this.nombreControles.contactoRemitenteNatural);
        datos.CorreoPersonaContacto = this.getValorControlString(this.nombreControles.correoElectronicoRemitenteNatural);
        datos.TelefonoPersonaContacto = this.getValorControlString(this.nombreControles.telefonoContactoRemitenteNatural);

        break;
      case "Jurídica":
        datos.IdTipoDocumentoRemitente = this.tipoDocumentoPersonaJuridica;
        datos.RucRemitente = this.getValorControlString(this.nombreControles.rucRemitente);
        datos.IdPaisRemitente = this.getValorControlNumber(this.nombreControles.paisRemitente);
        datos.RazonSocialRemitente = this.getValorControlString(this.nombreControles.razonSocialRemitente);
        datos.NombrePersonaContacto = this.getValorControlString(this.nombreControles.contactoRemitenteJuridica);
        datos.CorreoPersonaContacto = this.getValorControlString(this.nombreControles.correoElectronicoRemitenteJuridica);
        datos.TelefonoPersonaContacto = this.getValorControlString(this.nombreControles.telefonoContactoRemitenteJuridica);

        break;
      case "Localidad":
        datos.IdTipoDocumentoRemitente = this.getValorControlNumber(this.nombreControles.tipoDocumentoRemitenteComunidad);
        datos.NumeroDocumentoRemitente = this.getValorControlString(this.nombreControles.numeroDocumentoRemitenteComunidad);
        datos.NombreRemitente = this.getValorControlString(this.nombreControles.nombresRemitenteComunidad);
        datos.ApellidoPaternoRemitente = this.getValorControlString(this.nombreControles.apellidoPaternoRemitenteComunidad);
        datos.ApellidoMaternoRemitente = this.getValorControlString(this.nombreControles.apellidoMaternoRemitenteComunidad);
        datos.NombrePersonaContacto = this.getValorControlString(this.nombreControles.contactoRemitenteComunidad);
        datos.CorreoPersonaContacto = this.getValorControlString(this.nombreControles.correoElectronicoRemitenteComunidad);
        datos.TelefonoPersonaContacto = this.getValorControlString(this.nombreControles.telefonoContactoRemitenteComunidad);
        datos.DireccionPersonaContacto = this.getValorControlString(this.nombreControles.direccionContactoRemitenteComunidad);

        datos.IdComunidad = this.getValorControlNumber('idComunidad');
        datos.DireccionComunidad = this.getValorControlString('direccionComunidad');
        datos.NombreComunidad = this.getValorControlString('nombreComunidad');

        break;
    }

    datos.FormaEnvioCargo = this.getValorControlNumber('formaEnvioCargo');

    datos.IdGerenciaSenior = this.getValorControlNumber('idGerenciaSenior');
    datos.IdZona = this.getValorControlNumber('idZona');
    datos.IdLocalidad = this.getValorControlNumber('idLocalidad');
    datos.IdAnexo = this.getValorControlNumber('idAnexo');

    datos.NoAplicaDestinatario = this.form.controls[this.nombreControles.noAplicaDestinatario]?.value;

    if (!datos.NoAplicaDestinatario) {
      datos.IdDestinatarioInterno = this.campo.destinatariocompleto.ID;
      datos.IdDestinatarioInternoSharePoint = this.campo.destinatario.ID;
      datos.NombreUsuarioDestino = this.campo.destinatario.Name;
      datos.CorreoUsuarioDestino = this.campo.destinatario.Email;
      datos.IdAreaUsuarioDestino = this.campo.area.ID;
      datos.IdSedeUsuarioDestino = this.campo.sede.ID;
      datos.IdPuestoUsuarioDestino = this.campo.puesto.ID;
    }

    datos.Asunto = this.getValorControlString(this.nombreControles.asunto);
    datos.Referencia = this.getValorControlString(this.nombreControles.referencia);

    if (documentoPrincipal.length > 0) {
      datos.IdTipoDocumentoPrincipal = parseInt(documentoPrincipal[0].Tipo, 10);
      datos.NumeroDocumentoPrincipal = documentoPrincipal[0].Codigo;
      datos.TituloDocumentoPrincipal = documentoPrincipal[0].Descripcion;
    }

    const adjuntos: any[] = [];

    for (const ItemAdjunto of this.ItemsAdjuntos) {
      const adjunto = {
        NombreArchivo: ItemAdjunto.NombreArchivo,
        IdTipoDocumento: parseInt(ItemAdjunto.Tipo, 10),
        NumeroDocumento: ItemAdjunto.Codigo,
        Descripcion: ItemAdjunto.Descripcion,
        EsPrincipal: ItemAdjunto.EsPrincipal,
        Archivo: ItemAdjunto.Archivo
      };

      adjuntos.push(adjunto);
    }

    datos.AdjuntoComunicacion = adjuntos;

    datos.recaptchaToken = this.RecaptchaResolvedResponse;

    return datos;
  }

  public RecaptchaResolved(event: string): void {
    this.MostrarErrorRecaptcha = false;
    this.RecaptchaResolvedResponse = event;
  }

  onClickEnviar(): void {
    if (this.form.invalid) {
      FormHelper.ValidarFormGroup(this.form);
      if (this.language === "en") {
        this.toastr.warning('Enter the required fields.', 'Validation!');
      } else {
        this.toastr.warning('Ingrese los campos obligatorios.', '¡Validación!');
      }
      return;
    }

    if (this.ItemsAdjuntos.length === 0) {
      if (this.language === "en") {
        this.toastr.warning('You must add at least one document.', 'Validation!');
      } else {
        this.toastr.warning('Debe agregar al menos un documento.', '¡Validación!');
      }
      return;
    }

    if (!this._notasComponent?.validarValor()) {
      if (this.language === "en") {
        this.toastr.warning('You must add a commet for the note', 'Validation!');
      } else {
        this.toastr.warning('Debe ingresar un comentario para la nota', '¡Validación!');
      }
      return;
    }

    const elementoPrincipal = this.ItemsAdjuntos.filter((elemento: EComunicacionAdjuntoRegistrarDto) => {
      return elemento.EsPrincipal;
    });

    if (elementoPrincipal.length === 0) {
      if (this.language === "en") {
        this.toastr.warning('You must select a main document.', 'Validation!');
      } else {
        this.toastr.warning('Debe seleccionar un documento principal.', '¡Validación!');
      }
      return;
    }

    if (elementoPrincipal.length > 0) {
      if (elementoPrincipal[0].Tipo === '0') {
        if (this.language === "en") {
          this.toastr.warning('You must select a type to the main document.', 'Validation!');
        } else {
          this.toastr.warning('Debe seleccionar un tipo al documento principal.', '¡Validación!');
        }
        return;
      }
    }

    const autorizacion = this.form.get(this.nombreControles.autorizacion)?.value;

    if (!autorizacion) {
      if (this.language === "en") {
        this.toastr.warning('You must check the box to authorize the processing of your data.', 'Validation!');
      } else {
        this.toastr.warning('Debe marcar la casilla de autoización de tratamiento de sus datos.', '¡Validación!');
      }
      return;
    }

    if (!this.RecaptchaResolvedResponse) {
      if (this.language === "en") {
        this.toastr.warning('You must check the reCAPTCHA checkbox.', 'Validation!');
      } else {
        this.toastr.warning('Debe marcar la casilla de verificación de reCAPTCHA.', '¡Validación!');
      }
      this.MostrarErrorRecaptcha = true;
      return;
    }
    this.ResetRecaptcha = true;

    let mensajeConfirmacionGuardarComunicacion = "¿Esta seguro de guardar la comunicación?";
    if (this.language === "en") {
      mensajeConfirmacionGuardarComunicacion = "Are you sure to save the communication?";
    }

    this.mostrarModalConfirmacion(mensajeConfirmacionGuardarComunicacion, (isConfirm: boolean) => {
      if (!isConfirm) {
        return;
      }

      this.mostrarProgreso();

      const datosComunicacion = this.getJsonElementoComunicacion();

      this.comunicacionService.registrarComunicacion(datosComunicacion)
        .then((result: any) => {
          this.ocultarProgreso();
          this.ResetRecaptcha = false;

          if (result.data === null) {
            this.toastr.warning('Se encontró un problema en el registro de la comunicación', '¡Error!');
            this.ResetRecaptcha = false;
            return;
          }


          if (result.data.id !== undefined) {
            this._notasComponent?.guardarValor(
              result.data.id,
              datosComunicacion.CorreoPersonaContacto
            ).then(() => {
              sessionStorage.setItem('SesionRegistroPublico_CodigoComunicion', result.data.codigoComunicacion);
              //const url = window.location.origin + "/exito?lng=" + this.language;
              //window.location.href = url;
              const formaEnvio = this.form.get('formaEnvioCargo')?.value;
              const modo = datosComunicacion.TipoRemitente === 'Localidad' ? formaEnvio : 0;
              this.router.navigate([`/exito`], {
                queryParams: {
                  lng: this.language,
                  envio: modo
                }
              });
            });
          }
        })
        .catch((error: any) => {
          this.mostrarModalError('Error Registrar Comunicacion: ', error.message);
          this.ResetRecaptcha = false;
          this.ocultarProgreso();
        });
    },
      this.language === "en" ? 'Yes' : 'Sí',
      'No'
    );

  }

  convertirArrayByte = (arrayBuffer: any) => {
    const d: Deferred<any> = new Deferred<any>();
    const reader = new FileReader();

    reader.onloadend = function (e) {
      const arrayBuffer = e.target!.result;
      const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);
      const bytesArchivo = [].slice.call(uint8Array);
      d.resolve(bytesArchivo);
    };

    reader.readAsArrayBuffer(arrayBuffer);

    return d.promise;
  };

  changeLanguage(lang: string) {
    this.language = lang;
    if (lang !== this.i18NextService.language) {
      this.i18NextService.changeLanguage(lang).then(x => {
        this.updateState(lang);
        const url = window.location.origin + "/?lng=" + this.language + '&emp=' + this.idEmpresa;
        window.location.href = url;
        //document.location.reload();
      });
    }
  }

  private updateState(lang: string) {
    this.language = lang;

    if (this.language === 'es') {
      this.languageFlag = 'spain-bandera';
    }
    if (this.language === 'en') {
      this.languageFlag = 'eeuu-bandera';
    }
  }

  public cambiarIdentidadDocumento(): void {
    const valor = this.form.get('identificadorDocumento')?.value;
    if (valor === null || valor === undefined) {
      return;
    }
    this.form.get('sinComunidad')?.setValue(false);
    this.form.get('busquedaComunidad')?.setValue(null);
    this.form.get('nombreComunidad')?.setValue(null);
    this.form.get('direccionComunidad')?.setValue(null);
    this.form.get(this.nombreControles.tipoDocumentoRemitenteComunidad)?.setValue(null);
    this.form.get(this.nombreControles.numeroDocumentoRemitenteComunidad)?.setValue(null);
    this.form.get(this.nombreControles.nombresRemitenteComunidad)?.setValue(null);
    this.form.get(this.nombreControles.apellidoPaternoRemitenteComunidad)?.setValue(null);
    this.form.get(this.nombreControles.apellidoMaternoRemitenteComunidad)?.setValue(null);
    this.mostrarRucInvalidoComunidad = false;

    //this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.clearValidators();

    if (valor === 0) {
      this.mostrarBloqueValidacionPersonaComunidad = false;

      this.form.controls['busquedaComunidad'].enable();
      this.form.controls['idComunidad'].enable();
      this.form.controls['nombreComunidad'].enable();
      this.form.controls['direccionComunidad'].enable();
      this.form.controls[this.nombreControles.tipoDocumentoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.nombresRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteComunidad].disable();

      this.mostrarCamposPN = false;
      this.mostrarCamposPJ = false;
      this.mostrarCamposCo = true;
    } else if (valor === 1) {
      this.mostrarBloqueValidacionPersonaComunidad = true;

      this.form.controls['busquedaComunidad'].disable();
      this.form.controls['idComunidad'].disable();
      this.form.controls['nombreComunidad'].disable();
      this.form.controls['direccionComunidad'].disable();
      this.form.controls[this.nombreControles.tipoDocumentoRemitenteComunidad].enable();
      this.form.controls[this.nombreControles.numeroDocumentoRemitenteComunidad].enable();
      this.form.controls[this.nombreControles.nombresRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.apellidoPaternoRemitenteComunidad].disable();
      this.form.controls[this.nombreControles.apellidoMaternoRemitenteComunidad].disable();

      this.mostrarCamposPN = true;
      this.mostrarCamposPJ = false;
      this.mostrarCamposCo = false;
    }
  }

  public cambioFormaEnvioCargo(): void {
    const valor = this.form.get('formaEnvioCargo')?.value;
    if (valor === null || valor === undefined) {
      return;
    }
    this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.setValue(null);
    this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.setValue(null);
    this.form.get(this.nombreControles.correoElectronicoRemitenteComunidad)?.setValue(null);
    const clearValid = () => {
      this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.clearValidators();
      this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.clearValidators();
      this.form.get(this.nombreControles.correoElectronicoRemitenteComunidad)?.clearValidators();
    };
    const updateValid = () => {
      this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.updateValueAndValidity();
      this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.updateValueAndValidity();
      this.form.get(this.nombreControles.correoElectronicoRemitenteComunidad)?.updateValueAndValidity();
    };
    switch (valor) {
      case 0:
        clearValid();
        this.form.get(this.nombreControles.correoElectronicoRemitenteComunidad)?.setValidators([
          Validators.required,
          Validators.email
        ]);
        break;
      case 1:
        clearValid();
        this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.setValidators([
          Validators.required
        ]);
        break;
      case 2:
        clearValid();
        //this.form.get(this.nombreControles.telefonoContactoRemitenteComunidad)?.setValidators([Validators.required]);
        break;
    }
    updateValid();
  }
}
