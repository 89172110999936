import { ListItemMaster } from './ListItemMaster';
import { ListItem } from './ListItem';
import { Constantes } from '../../utils/Constantes';
import { IListItemTransaccional } from './IListItemTransaccional';

export class Log extends ListItem implements IListItemTransaccional {
    descripcion: string;
    pagina: string;

    constructor() {
        super();

        this.Title = "";
        this.descripcion = "";
        this.pagina = "";
    }

    public static setNuevoElemento(pagina: string, titulo: string, descripcion: string): Log {
        const objeto = new Log();
        objeto.descripcion = JSON.stringify(descripcion);
        objeto.pagina = pagina;
        objeto.Title = titulo;

        return objeto;
    }

    public getJsonElemento(): any {
        const datos : any = {};
        datos[Constantes.columnas.DescripcionError] = this.descripcion;
        datos[Constantes.columnas.Modulo] = this.pagina;
        datos[Constantes.columnas.Title] = this.Title;

        return datos;
    }
}