<div class="container">
    <div class="d-flex align-items-center justify-content-center monitor">
        <mat-card class="col-lg-6">

            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div class="mt-4 mb-4 col-lg-8 text-center">
                            <p>{{ mensajeError }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card>
    </div>

</div>