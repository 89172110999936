import { Injectable } from '@angular/core';
import { Deferred } from "ts-deferred";
import { TokenService } from '../services/token.service';

export class Funciones {

    constructor(
        private tokenService: TokenService,
    ) {
    }

    public EnviarDatosPost(url: string, datos: any): Promise<any> {
        console.dir(url);
        console.dir(datos);
        const d: Deferred<any> = new Deferred<any>();

        const jsonData = JSON.stringify(datos);
        let error = "";

        try {
            const spRequest = new XMLHttpRequest();
            spRequest.open("POST", url, true);
            spRequest.setRequestHeader("Content-Type", "application/json");
            spRequest.onreadystatechange = () => {
                if (spRequest.readyState === 4 && spRequest.status === 200) {
                    const resultOperacion = JSON.parse(spRequest.responseText);
                    console.dir(resultOperacion);
                    d.resolve(resultOperacion);
                } else if (spRequest.readyState === 4 && spRequest.status !== 200) {
                    console.log("Error ocurrido !");
                    d.reject(spRequest.readyState);
                }
            };
            spRequest.onerror = function () {
                error = "Error en invocación de Servicio: " + url + "- Status: " + this.status + " - StatusText: " + spRequest.statusText + " - Response: " + spRequest.response;
                d.reject(error);
            };
            spRequest.send(jsonData);
        } catch (err) {
            console.dir(err);
        }

        return d.promise;
    }

    public static EnviarDatosGet(url: string): Promise<any> {
        const d: Deferred<any> = new Deferred<any>();

        try {
            const spRequest = new XMLHttpRequest();

            spRequest.open("GET", url, true);
            spRequest.setRequestHeader("Content-Type", "application/json");

            spRequest.onreadystatechange = () => {
                if (spRequest.readyState === 4 && spRequest.status === 200) {
                    const resultOperacion = JSON.parse(spRequest.responseText);
                    d.resolve(resultOperacion);
                } else if (spRequest.readyState === 4 && spRequest.status !== 200) {
                    console.log("Error ocurrido !");
                    d.reject(spRequest.readyState);
                }
            };

            spRequest.send();
        } catch (err) {
            console.dir(err);
        }

        return d.promise;
    }

    static esUndefinedNullOrEmpty(valor: any): boolean {
        if (valor === undefined || valor === null || valor === "") {
            return true;
        }
        return false;
    }

    static ordenarArregloAsc(arreglo: any, propiedad: any): any[] {
        arreglo.sort(function (a: any, b: any) {
            return a[propiedad] > b[propiedad];
        });
        return arreglo;
    }

    static ordenarArregloDesc(arreglo: any, propiedad: any): any[] {
        this.ordenarArregloAsc(arreglo, propiedad);
        arreglo.reverse();
        return arreglo;
    }

    static obtenerCorreos(usuarios: any): string {
        let correos = "";

        for (const usuario of usuarios) {
            if (correos.length > 0) {
                correos += ";";
            }
            correos += usuario.Email;
        }

        return correos;
    }

    static obtenerFechaHoraDocumento(): string {
        const fecha = new Date();

        const fechaHora =
            fecha.getFullYear() +
            "" +
            (fecha.getMonth() + 1).toString().padStart(2, "0") +
            "" +
            fecha.getDate().toString().padStart(2, "0") +
            "_" +
            fecha.getHours().toString().padStart(2, "0") +
            "" +
            fecha.getMinutes().toString().padStart(2, "0") +
            "" +
            fecha.getSeconds().toString().padStart(2, "0");
        /* + "" + fecha.getMilliseconds()*/

        return fechaHora;
    }

    static ConvertirDateToString(fecha: Date): string {
        let result = "";
        const dia = fecha.getDate() > 9 ? fecha.getDate() : "0" + fecha.getDate();
        const mes =
            fecha.getMonth() + 1 > 9
                ? fecha.getMonth() + 1
                : "0" + (fecha.getMonth() + 1);

        result = [dia, mes, fecha.getFullYear()].join("/");
        return result;
    }

    static MostrarFechaFormatoLetras(valor: any): string {
        let result = "";
        const parts = valor.split("/");

        if (parts.length === 3) {
            const mes = parseInt(parts[1], 10);
            const mesLetras = this.ObtenerMesEnLetras(mes);
            result = parts[0] + " de " + mesLetras + " del " + parts[2];
        }

        return result;
    }

    static ObtenerMesEnLetras(valor: number): string {
        let result = "";
        if (valor === 1) {
            result = "Enero";
        } else if (valor === 2) {
            result = "Febrero";
        } else if (valor === 3) {
            result = "Marzo";
        } else if (valor === 4) {
            result = "Abril";
        } else if (valor === 5) {
            result = "Mayo";
        } else if (valor === 6) {
            result = "Junio";
        } else if (valor === 7) {
            result = "Julio";
        } else if (valor === 8) {
            result = "Agosto";
        } else if (valor === 9) {
            result = "Septiembre";
        } else if (valor === 10) {
            result = "Octubre";
        } else if (valor === 11) {
            result = "Noviembre";
        } else if (valor === 12) {
            result = "Diciembre";
        }

        return result;
    }

    static MostrarFechaFormatoLetras2(valor: Date): string {
        var dias = new Array(7);
        dias[0] = "Domingo";
        dias[1] = "Lunes";
        dias[2] = "Martes";
        dias[3] = "Miercoles";
        dias[4] = "Jueves";
        dias[5] = "Viernes";
        dias[6] = "Sabado";

        let result = "";
        const mes = valor.getMonth() + 1;
        const dia = valor.getDay();
        const numeroDia = valor.getDate();
        const anio = valor.getFullYear();
        const mesLetras = this.ObtenerMesEnLetras(mes);
        const diaLetras = dias[dia];
        result = diaLetras + ", " + numeroDia + " de " + mesLetras + " del " + anio;

        return result;
    }

    static PadString(pad: any, user_str: any, pad_pos: any) {
        if (typeof user_str === "undefined") return pad;
        if (pad_pos == "l") {
            return (pad + user_str).slice(-pad.length);
        } else {
            return (user_str + pad).substring(0, pad.length);
        }
    }

    static ObtenerObjetoEnListaPorID(Items: any, ID: string): any {
        let objeto = null;
        var ItemsFiltrados = Items.filter((x: any) => x.ID === ID);
        if (ItemsFiltrados.length > 0) {
            objeto = ItemsFiltrados[0];
        }

        return objeto;
    }

    static EsNullVacioUndefined(valor: any): boolean {
        if (valor === undefined || valor === null || valor === "") {
            return true;
        } else {
            return false;
        }
    }
}