import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClienteApiHttpService } from './cliente-api-http.service';
import { Router, UrlSerializer } from '@angular/router';
import { sp } from '@pnp/sp';
import { HttpClient } from '@angular/common/http';
import pnp from '@pnp/pnpjs';
import { Deferred } from 'ts-deferred';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { RegistrarNotaComunicacionExterna } from '../models/entidades/RegistrarNotaComunicacionExterna';

@Injectable({ providedIn: 'root' })
export class ComunicacionService {

  private uriNotificarExpedienteExterno: string = environment.apiUrlBackEnd + '/api/expediente/notificar-expediente-externo';
  private uriRegistrarComunicacion: string = environment.apiUrlBackEnd + '/api/mesapartes/registrar';
  private uriRegistrarMotas: string = environment.apiUrlBackEnd + '/api/mesapartes/notas';

  constructor(
    private httpClient: HttpClient,
    private clienteApiHtppService: ClienteApiHttpService,
    private router: Router,
    private datePipe: DatePipe,
    private serializer: UrlSerializer) {
    sp.setup({
      sp: {
        baseUrl: `${environment.proxyUrl}`
      }
    });
  }

  public registrarComunicacion(body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriRegistrarComunicacion, body).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );

    });
  }

  public notificarExpedienteExterno(body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriNotificarExpedienteExterno, body).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );

    });
  }

  public registrarNota(item: RegistrarNotaComunicacionExterna): Promise<boolean> {
    const formData = this.ObtenerFormDataDesdeObjecto(item);
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(this.uriRegistrarMotas, formData).subscribe(res => {
        resolve(true);
      });
    });
  }

  private GetQueryStringFromObject(request: any): string {
    request = this.TransformToStandardDate(request);
    const tree = this.router.createUrlTree([], { queryParams: request });
    const params = this.serializer.serialize(tree);
    const split = params.split('?');
    if (split.length <= 1) {
      return '';
    }
    const parameters = split[split.length - 1];
    return parameters;
  }

  private ObtenerFormDataDesdeObjecto(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        continue;
      }
      const formKey = namespace
        ? (object[property] instanceof File ? namespace : `${namespace}[${property}]`)
        : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
        this.ObtenerFormDataDesdeObjecto(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  private TransformToStandardDate(object: any): any {
    if (object instanceof Date) {
      return this.datePipe.transform(object, 'yyyy-MM-ddTHH:mm:ss');
    }
    else if (typeof object === 'object') {
      for (let key in object) {
        var value = object[key];
        object[key] = this.TransformToStandardDate(value);
      }
    }
    return object;
  }
}
