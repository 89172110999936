import { Constantes } from './../../utils/Constantes';
import { SPParse } from '../../utils/SPParse';

export class EParametrosGenerales {
    ID: string;
    Title: string;
    Valor: string;
    Longitud: number;
    Habilitado: boolean;

    constructor() {
        this.ID = '0';
        this.Title = '';
        this.Valor = '';
        this.Longitud = 0;
        this.Habilitado = false;
    }

    public static getColumnasSelect(): string[] {
        return [
            Constantes.columnas.ID,
            Constantes.columnas.Title,
            Constantes.columnas.Valor,
            Constantes.columnas.Longitud,
            Constantes.columnas.Habilitado,
        ];
    }

    public static parseJson(element: any): EParametrosGenerales {

        const objeto = new EParametrosGenerales();
        objeto.ID = SPParse.getNumber(element[Constantes.columnas.ID]).toString();
        objeto.Title = SPParse.getString(element[Constantes.columnas.Valor]);
        objeto.Valor = SPParse.getString(element[Constantes.columnas.Valor]);
        objeto.Longitud = SPParse.getNumber(element[Constantes.columnas.Longitud]);
        objeto.Habilitado = SPParse.getBool(element[Constantes.columnas.Habilitado]);
        return objeto;
    }
}
