import { Constantes } from "./../../utils/Constantes";
import { SPParse } from "../../utils/SPParse";

export class EMaestro {
    ID: string;
    Title: string;
    TitleEN: string;
    Longitud: any;
    Habilitado: boolean;

    constructor() {
        this.ID = "0";
        this.Title = "";
        this.TitleEN = "";
        this.Longitud = null;
        this.Habilitado = false;
    }

    public static getColumnasSelect(): string[] {
        return [
            Constantes.columnas.ID,
            Constantes.columnas.Title,
            Constantes.columnas.Habilitado,
        ];
    }

    public static parseJsonBack(element: any): EMaestro {

        const objeto = new EMaestro();
        objeto.ID = SPParse.getNumber(element["id"]).toString();
        objeto.Title = SPParse.getString(element["title"]);
        objeto.TitleEN = SPParse.getString(element["titleEN"]);
        objeto.Longitud = element["longitud"];
        objeto.Habilitado = SPParse.getBool(element["habilitado"]);
        return objeto;
    }


    public static parseJsonListBack(elements: any, lng: string): EMaestro[] {
        const Items: EMaestro[] = [];
        for (const element of elements) {
            const objeto = EMaestro.parseJsonBack(element);
            if (lng === "en") {
                objeto.Title = objeto.TitleEN;
            }
            Items.push(objeto);
        }
        return Items;
    }

    public static parseJson(element: any): EMaestro {

        const objeto = new EMaestro();
        objeto.ID = SPParse.getNumber(element[Constantes.columnas.ID]).toString();
        objeto.Title = SPParse.getString(element[Constantes.columnas.Title]);
        objeto.Habilitado = SPParse.getBool(element[Constantes.columnas.Habilitado]);
        return objeto;
    }
}
