<ng-container class="mat-Control-custom" *ngIf="Control?.touched && Control?.errors?.required">
  Campo obligatorio
</ng-container>
<ng-container class="mat-Control-custom" *ngIf="(Control?.touched) && Control?.errors?.minlength">
  Mínimo {{Validadores?.minlength.requiredLength}} caracteres
</ng-container>
<ng-container class="mat-Control-custom" *ngIf="(Control?.touched) && Control?.errors?.maxlength">
  Máximo {{Validadores?.maxlength.requiredLength}} caracteres
</ng-container>
<ng-container class="mat-Control-custom" *ngIf="(Control?.touched) && Control?.errors?.email">
  Ingresa un correo válido
</ng-container>
<ng-container class="mat-Control-custom" *ngIf="(Control?.touched) && Control?.errors?.min">
  El valor mínimo es {{Validadores?.min.min}}
</ng-container>
<ng-container class="mat-Control-custom" *ngIf="(Control?.touched) && Control?.errors?.max">
  El valor máximo es {{Validadores?.max.max}}
</ng-container>
