import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { HttpRequestInterceptor } from './shared/interceptors/HttpRequestInterceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppTranslationModule } from './app-translation.module';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { I18NEXT_SERVICE, I18NextModule, I18NextLoadResult, ITranslationService, defaultInterpolationFormat  } from 'angular-i18next';
import   LanguageDetector from 'i18next-browser-languagedetector';
import { AppComponent } from './app.component';
import { ModalDialog } from "./shared/components/modal/modal.component";
import { NgxSpinnerModule } from 'ngx-spinner';
import { NuevoRegistroComponent } from './components/mesa-partes/nuevo-registro/nuevo-registro.component';
import { ExitoComponent } from './components/mesa-partes/exito/exito.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorComponent } from './components/mesa-partes/error/error.component';
import { ErrorValidationDisplayComponent } from './shared/components/error-validation-display/error-validation-display.component';
import { NotasComponent } from './components/mesa-partes/notas/notas.component';

const materialModules = [
  MatSidenavModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatToolbarModule,
  MatButtonModule,
  MatListModule,
  MatTabsModule,
  MatGridListModule,
  MatTableModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatMenuModule,
  MatCardModule,
  MatDialogModule,
  MatSelectModule,
  MatDividerModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatSortModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatRippleModule,
  MatNativeDateModule,
  MatBadgeModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatChipsModule
];

@NgModule({
  declarations: [
    AppComponent,
    ModalDialog,
    NuevoRegistroComponent,
    NotasComponent,
    ExitoComponent,
    ErrorComponent,
    ErrorValidationDisplayComponent
  ],
  imports: [
    ...materialModules,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppTranslationModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    I18NextModule.forRoot(),
    RecaptchaModule
  ],
  exports: [
    ...materialModules,
    HttpClientModule,
    ModalDialog,
    NgxSpinnerModule
  ],
  entryComponents:[
    ModalDialog
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true
  },{
    provide: MAT_DATE_LOCALE, useValue: 'es-GB'
  },
  DatePipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }
