import { Constantes } from '../../utils/Constantes';

export class ETipoDocumento {
  ID: number;
  Title: string;
  TipoPersona: string;
  Longitud: number;

  constructor() {
    this.ID = 0;
    this.Title = '';
    this.TipoPersona = '';
    this.Longitud = 0;
  }

  static parseJsonList(elements: any[]): ETipoDocumento[] {
    let lista: ETipoDocumento[] = [];

    if (elements) {
      lista = elements.map(element => {
        const item = new ETipoDocumento();
        if (item) {
          item.ID = element[Constantes.columnas.ID];
          item.Title = element[Constantes.columnas.Title];
          item.TipoPersona = element[Constantes.columnas.TipoPersona];
          item.Longitud = element[Constantes.columnas.Longitud];
        }
        return item;
      });
    }

    return lista;
  }

  public static getColumnasSelect(): string[] {
      return [
          Constantes.columnas.ID,
          Constantes.columnas.Title,
          Constantes.columnas.TipoPersona,
          Constantes.columnas.Longitud
      ];
  }
}
