import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ModalDialog, IDialogData } from "../components/modal/modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UtilService } from "../services/util.service";
import { ModalType } from "../../shared/utils/Enumeradores";
import { MasterService } from "../services/master.service";
import { Log } from "../models/base/Log";
import { Constantes } from "../../shared/utils/Constantes";
import { environment } from "src/environments/environment";

export class FormularioBase {
  nombrePagina: string;
  submitted: boolean;
  form: FormGroup;

  constructor(nombrePagina: string, public dialog: MatDialog, public route: ActivatedRoute, public router: Router, public spinner: NgxSpinnerService, public utilService: UtilService, public masterService: MasterService) {
    this.nombrePagina = nombrePagina;
    this.submitted = false;   
  }

  mostrarProgreso() {
    this.spinner.show();
  }

  ocultarProgreso() {
    this.spinner.hide();
  }

  mostrarModalError(mensaje: string, error: any, callback?: any): void {
    this.ocultarProgreso();

    const dialogData = <IDialogData>{};
    dialogData.mensaje = "¡Se encontró un error en el proceso!";
    dialogData.titulo = "";
    dialogData.tipoModal = ModalType.Error;

    this.masterService.guardarLog(Log.setNuevoElemento(this.nombrePagina, mensaje, error));

    const dialogRef = this.dialog.open(ModalDialog, {
      width: "",
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback(result);
      }
    });
  }

  getValorControlString(nombreControl: string): string {
    if (this.form.get(nombreControl)?.value) {
      return this.form.get(nombreControl)?.value.toString();
    }
    return "";
  }

  getValorControlNumber(nombreControl: string): number {
    const valor = this.form.get(nombreControl)?.value;

    if (valor && valor.toString().length != 0) {
      return parseInt(valor);
    }
    return 0;
  }

  getValorControlFloat(nombreControl: string): number {
    const valor = this.form.get(nombreControl)?.value;

    if (valor && valor.toString().length != 0) {
      return parseFloat(valor);
    }
    return 0;
  }

  getValorControlPeoplePicker(nombreControl: string): number {
    const usuario = this.form.get(nombreControl)?.value;

    if (usuario) {
      if (Array.isArray(usuario)) {
        if (usuario.length > 0) {
          return usuario[0].Id;
        }
        return 0;
      } else {
        return usuario.Id;
      }
    }

    return 0;
  }

  mostrarModalSuccess(titulo: string, mensaje: string, callback: any, textoBotonSi : string): void {
    this.ocultarProgreso();

    const dialogData = <IDialogData>{};
    dialogData.mensaje = mensaje;
    dialogData.titulo = titulo;
    dialogData.tipoModal = ModalType.Success;
    dialogData.textoBotonSi = textoBotonSi;

    const dialogRef = this.dialog.open(ModalDialog, {
      width: "",
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback(result);
      }
    });
  }

  mostrarModalInformativo(titulo: string, mensaje: string): void {
    this.ocultarProgreso();

    const dialogData = <IDialogData>{};
    dialogData.mensaje = mensaje;
    dialogData.titulo = titulo;
    dialogData.tipoModal = ModalType.Warning;

    const dialogRef = this.dialog.open(ModalDialog, {
      width: "",
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  getErrorRequerido(nombreControl: string): string {
    const control = this.form.controls[nombreControl];
    if (!control.valid && (this.submitted || control.dirty || control.touched)) {
      return '<span class="form - message">Este campo es obligatorio.</span>';
    }
    return "";
  }

  public obtenerParametroID(): number {
    let id = this.route.snapshot.params.id;

    if (!id) {
      return 0;
    } else {
      id = parseInt(id);
    }

    return id;
  }

  mostrarModalConfirmacion(mensaje: string, callback: any, textoBotonSi: string, textoBotonNo: string): void {
    this.ocultarProgreso();

    const dialogData = <IDialogData>{};
    dialogData.mensaje = mensaje;
    dialogData.titulo = "";
    dialogData.tipoModal = ModalType.Confirm;
    dialogData.textoBotonSi = textoBotonSi;
    dialogData.textoBotonNo = textoBotonNo;

    const dialogRef = this.dialog.open(ModalDialog, {
      width: "",
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback(result);
      }
    });
  }  

  mostrarModalInformativoConAccion(titulo: string, mensaje: string, callback: any, textoBotonSi : string): void {
    this.ocultarProgreso();

    const dialogData = <IDialogData>{};
    dialogData.mensaje = mensaje;
    dialogData.titulo = titulo;
    dialogData.tipoModal = ModalType.Warning;
    dialogData.textoBotonSi = textoBotonSi;

    const dialogRef = this.dialog.open(ModalDialog, {
      width: "",
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback(result);
      }
    });
  }  

  getRutaBase() {
    return `${environment.proxyUrl}`;
  }
}
