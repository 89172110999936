import { RequestSerializerService } from './request-serializer.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sp } from '@pnp/sp';
import '@pnp/sp/webs';
import '@pnp/sp/lists';
import '@pnp/sp/items';
import { environment } from 'src/environments/environment';
import { Constantes } from '../utils/Constantes';
import { MasterService } from './master.service';
import { ETipoDocumento } from '../models/entidades/ETipoDocumento';
import { ListItem } from '../models/base/ListItem';

@Injectable({
  providedIn: 'root'
})
export class AdministracionService {

  private uriAdministracion: string = `${environment.apiUrlBackEnd}/api/administracion`

  constructor(
    private httpClient: HttpClient,
    private requestSerializerService: RequestSerializerService,
    public masterService: MasterService
  ) {
    sp.setup({
      sp: {
        baseUrl: `${environment.proxyUrl}`
      }
    });
  }

  async getItemsAdmTipoDocumento(): Promise<ETipoDocumento[]> {
    let ItemsTipoDocumento: ETipoDocumento[] = [];

    const selectFields = ETipoDocumento.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmTipoDocumento).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsTipoDocumento = ETipoDocumento.parseJsonList(results.results);
    }

    return ItemsTipoDocumento;
  }

  async getItemsAdmTipoDocGestion(): Promise<ListItem[]> {
    let ItemsTipoDocGestion: ListItem[] = [];

    const selectFields = ListItem.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmTipoDocGestion).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsTipoDocGestion = ListItem.parseJsonList(results.results);
    }

    return ItemsTipoDocGestion;
  }

  async getItemsAdmMedioRecepcion(): Promise<ListItem[]> {
    let ItemsTipoDocGestion: ListItem[] = [];

    const selectFields = ListItem.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmMedioRecepcion).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsTipoDocGestion = ListItem.parseJsonList(results.results);
    }

    return ItemsTipoDocGestion;
  }

  async getItemsAdmArea(): Promise<ListItem[]> {
    let ItemsTipoDocGestion: ListItem[] = [];

    const selectFields = ListItem.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmArea).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsTipoDocGestion = ListItem.parseJsonList(results.results);
    }

    return ItemsTipoDocGestion;
  }

  async getItemsAdmPais(): Promise<ListItem[]> {
    let ItemsPais: ListItem[] = [];

    const selectFields = ListItem.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmPais).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsPais = ListItem.parseJsonList(results.results);
    }

    return ItemsPais;
  }

  async getItemsAdmTema(): Promise<ListItem[]> {
    let ItemsPais: ListItem[] = [];

    const selectFields = ListItem.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmTema).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsPais = ListItem.parseJsonList(results.results);
    }

    return ItemsPais;
  }

  async getItemsAdmProyecto(): Promise<ListItem[]> {
    let ItemsPais: ListItem[] = [];

    const selectFields = ListItem.getColumnasSelect();

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmProyecto).items.select(...selectFields);

    const results = await query.top(4999).getPaged().then(p => {
      return p;
    });

    if (results.results.length > 0) {
      ItemsPais = ListItem.parseJsonList(results.results);
    }

    return ItemsPais;
  }

  public async getItemsAdmProveedor(
    nombre: string,
    tipo: string,
    empresa: number
  ): Promise<ListItem[]> {
    const params = this.requestSerializerService.ObtenerQueryStringDesdeObjeto({
      valor: nombre,
      tipoPersona: tipo,
      idEmpresa: empresa
    });
    return new Promise<ListItem[]>((resolve, reject) => {
      this.httpClient.get<any>(`${this.uriAdministracion}/filtrar-proveedores?${params}`).subscribe(
        (res) => resolve(res.data),
        (error: any) => reject(error)
      );
    });
  }

  public async obtenerUltimoId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get<any>(`${this.uriAdministracion}/ultima-comunicacion`).subscribe(
        (res) => resolve(res.data),
        (error: any) => reject(error)
      );
    });
  }
}
