import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-error-validation-display',
  templateUrl: './error-validation-display.component.html',
  styleUrls: ['./error-validation-display.component.css']
})
export class ErrorValidationDisplayComponent implements OnInit {

  public Validadores: ValidationErrors | null = null;
  public Control: AbstractControl | null = null;

  @Input() set FormControl(formControl: AbstractControl | null) {
    if (formControl && formControl !== null) {
      console.log('Set control');
      this.Control = formControl;
      const validators = this.Control?.validator!({} as AbstractControl);
      if (validators) {
        this.Validadores = validators;
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
