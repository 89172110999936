<div class="container">

  <div class=" d-flex justify-content-between align-items-center mt-4 mb-4">
    <div class="d-flex align-items-center">
      <div class="img-mob mr-3">
        <img src="{{urlLogo}}" width="80" alt="">
      </div>
      <div>
        <h2> <span class="color-line">|&nbsp;</span> {{'Nuevo_TituloFormulario' | i18next}}</h2>
      </div>
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="beforeMenu"> <span><img src="assets/img/{{languageFlag}}.png"
            height="24" alt=""></span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="changeLanguage('es')"> <span class="mr-3"><img src="assets/img/spain-bandera.png"
              height="24" alt=""></span> Español</button>
        <button mat-menu-item (click)="changeLanguage('en')"> <span class="mr-3"><img src="assets/img/eeuu-bandera.png"
              height="24" alt=""></span> English</button>
      </mat-menu>
    </div>

  </div>
  <div>
    <!-- <hr class="mt-2 mb-2"> -->
    <span style="color: #2680ab">{{'Nuevo_InfoHorarioMPV' | i18next}}</span>
  </div>
  <mat-card class="mt-4 mb-3">

    <div class="row">

      <form [formGroup]="form">

        <!--datos remitente-->
        <div class="col-md-12 mb-3">

          <div class="subtitulo-p">{{ 'Nuevo_DatosRemitente' | i18next}}</div>

          <mat-card>
            <div class="row">
              <div class="col-lg-12 hide-div-xs">
                <div class="row">
                  <div class="col-12 col-md-3 col-lg-2"></div>
                  <div class="col-12 col-md-3 col-lg-2"></div>
                  <div class="col-12 col-md-6 col-lg-8 ps-2">
                    <div class="d-flex color-main">
                      <div>
                        <mat-icon class="bottom-arrow">keyboard_double_arrow_down</mat-icon>
                      </div>
                      <div class="col color-main contenedor-mensaje">
                        {{ textoMesaPartesComunidad }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <mat-radio-group class="row" aria-label="Select an option"
                  [formControlName]="nombreControles.tipoRemitente" (change)="onChangeTipoRemitente($event)">
                  <mat-radio-button class="col-12 col-md-3 col-lg-2" value="Natural">
                    {{ 'Nuevo_PersonaNatural' | i18next}}
                  </mat-radio-button>
                  <mat-radio-button class="col-12 col-md-3 col-lg-2" value="Jurídica">
                    {{ 'Nuevo_PersonaJuridica' | i18next}}
                  </mat-radio-button>
                  <mat-radio-button class="col-12 col-md-3 col-lg-2" value="Localidad">
                    {{ 'Nuevo_Comunidad' | i18next}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-lg-12 show-div-xs">
                <div class="row">
                  <div class="col-12 col-md-3 col-lg-2"></div>
                  <div class="col-12 col-md-3 col-lg-2"></div>
                  <div class="col-12 col-md-6 col-lg-8 ps-2">
                    <div class="d-flex color-main">
                      <div>
                        <mat-icon>keyboard_double_arrow_up</mat-icon>
                      </div>
                      <div class="col color-main contenedor-mensaje">
                        {{ 'Nuevo_DetalleRadioComunidad' | i18next }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" [hidden]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'">
                <div class="row">
                  <div class="col-12 col-md-3 col-lg-2"></div>
                  <div class="col-12 col-md-3 col-lg-2"></div>
                  <div class="col-12 col-md-6 col-lg-8 ps-4">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                      formControlName="identificadorDocumento" (change)="cambiarIdentidadDocumento()">
                      <mat-radio-button [value]="0">
                        <div class="text-wrap">{{ 'Nuevo_IdentidadDocumentoRepresentante' | i18next }}</div>
                      </mat-radio-button>
                      <mat-radio-button [value]="1">
                        <div class="text-wrap">{{ 'Nuevo_IdentidadDocumentoParticular' | i18next }}</div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <div>
                <hr>
              </div>

            </div>

            <!-- #region Bloque validación persona natural-->
            <div class="row" [hidden]="!mostrarBloqueValidacionPersonaNatural">
              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_TipoDocumento' | i18next}} </mat-label>
                <mat-select [formControlName]="nombreControles.tipoDocumentoRemitenteNatural">
                  <mat-option *ngFor="let Item of ItemsTipoDocumentoIdentidad"
                    (click)="eventoSeleccionarTipoDocumentoNatural(Item)" [value]="Item.ID">{{ Item.Title }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_NumeroDocumento' | i18next}}</mat-label>
                <input aria-label="Number" matInput autocomplete="off" [maxlength]="longitudTipoDocumentoIdentidad"
                  [formControlName]="nombreControles.numeroDocumentoRemitenteNatural">
              </mat-form-field>

              <div class="col-lg-4">
                <button type="button" class="btn validar" color="primary" (click)="onClickValidarRemitenteNatural()">{{'Nuevo_BotonValidar' | i18next}}</button>
              </div>

              <div class="col-12 red-alert mb-3" *ngIf="mostrarRucInvalidoNatural">
                {{ 'Nuevo_RucInvalido' | i18next }}
              </div>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_Nombres' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.nombresRemitenteNatural">
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_ApellidoPaterno' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="100"
                  [formControlName]="nombreControles.apellidoPaternoRemitenteNatural">
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_ApellidoMaterno' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="100"
                  [formControlName]="nombreControles.apellidoMaternoRemitenteNatural">
              </mat-form-field>
            </div>
            <!-- #endregion -->

            <!-- #region Bloque validación persona particular (Comunidad)-->
            <div class="row" [hidden]="!mostrarBloqueValidacionPersonaComunidad">
              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_TipoDocumento' | i18next}} </mat-label>
                <mat-select [formControlName]="nombreControles.tipoDocumentoRemitenteComunidad">
                  <mat-option *ngFor="let Item of ItemsTipoDocumentoIdentidad"
                    (click)="eventoSeleccionarTipoDocumentoComunidad(Item)" [value]="Item.ID">{{ Item.Title }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_NumeroDocumento' | i18next}}</mat-label>
                <input aria-label="Number" matInput autocomplete="off" [maxlength]="longitudTipoDocumentoIdentidad"
                  [formControlName]="nombreControles.numeroDocumentoRemitenteComunidad">
              </mat-form-field>

              <div class="col-lg-4">
                <button type="button" class="btn validar" color="primary" (click)="onClickValidarRemitenteComunidad()">{{'Nuevo_BotonValidar' | i18next}}</button>
              </div>

              <div class="col-12 red-alert mb-3" *ngIf="mostrarRucInvalidoComunidad">
                {{ 'Nuevo_RucInvalido' | i18next }}
              </div>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_Nombres' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.nombresRemitenteComunidad">
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_ApellidoPaterno' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="100"
                  [formControlName]="nombreControles.apellidoPaternoRemitenteComunidad">
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_ApellidoMaterno' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="100"
                  [formControlName]="nombreControles.apellidoMaternoRemitenteComunidad">
              </mat-form-field>
            </div>
            <!-- #endregion -->

            <!-- #region persona juridica-->
            <div class="row" [hidden]="!mostrarCamposPJ">
              <mat-form-field class="col-lg-4" appearance="outline" [hidden]="true">
                <mat-label>{{ 'Nuevo_Pais' | i18next}}</mat-label>
                <mat-select [formControlName]="nombreControles.paisRemitente">
                  <mat-option *ngFor="let Item of ItemsPaisRemitente" (click)="eventoSeleccionarPais(Item)"
                    [value]="Item.ID">{{ Item.Title }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="d-flex justify-content-between col-lg-4 col-sm-12">
                <mat-form-field class="col-lg-9 col-sm-10 mt-3" appearance="outline">
                  <mat-label>{{ 'Nuevo_RUC' | i18next}}</mat-label>
                  <input aria-label="Number" matInput autocomplete="off" [maxlength]="longitudPersonaJuridica" [formControlName]="nombreControles.rucRemitente">
                  <mat-error>
                    <app-error-validation-display [FormControl]="form.get(nombreControles.rucRemitente)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>

                <div class="align-self-center col-lg-2 col-sm-1">
                  <button type="button" mat-raised-button color="primary" (click)="onClickValidarRemitente()">{{'Nuevo_BotonValidar' | i18next}}</button>
                </div>
              </div>
              <div class="col-12 red-alert mb-3" *ngIf="mostrarRucInvalidoJuridica">
                {{ 'Nuevo_RucInvalido' | i18next }}
              </div>

              <mat-form-field class="col-lg-12" appearance="outline">
                <mat-label>{{ 'Nuevo_RazonSocial' | i18next}}</mat-label>
                <input aria-label="Number" matInput autocomplete="off" maxlength="100"
                  [formControlName]="nombreControles.razonSocialRemitente">
              </mat-form-field>
            </div>
            <!-- #endregion -->

            <!--comunidad-->
            <div class="row" [hidden]="!mostrarCamposCo">
              <mat-form-field class="col-6 col-md-8 mb-2" appearance="outline">
                <mat-label>{{"Nuevo_Comunidad"|i18next}}:</mat-label>
                <input matInput formControlName="busquedaComunidad" [matAutocomplete]="auto2"
                  (keyup)="eventoFiltrarComunidad()">
                <mat-autocomplete #auto2="matAutocomplete">
                  <mat-option *ngIf="IsLoadingComunidad">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </mat-option>
                  <ng-container *ngIf="!IsLoadingComunidad">
                    <mat-option (onSelectionChange)="seleccionComunidad(Item, $event)"
                      *ngFor="let Item of ItemsComunidadProveedorFiltrados" [value]="Item.title">
                      {{Item.title}}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <div class="col-6 col-md-4">
                <mat-checkbox class="mt-3" formControlName="sinComunidad"
                  (change)="cambiarSeleccionMostrarDireccionComunidad()">
                  {{ 'Nuevo_ComunidadNoEncontrada' | i18next }}
                </mat-checkbox>
              </div>
              <div class="col-12 mb-1" [hidden]="!this.form.controls['sinComunidad'].value">
                <div class="row">
                  <mat-form-field class="col-md-6 col-12" appearance="outline">
                    <mat-label>{{ 'Nuevo_NombreComunidad' | i18next }}</mat-label>
                    <input matInput formControlName="nombreComunidad" maxlength="250">
                  </mat-form-field>
                  <mat-form-field class="col-md-6 col-12" appearance="outline">
                    <mat-label>{{ 'Nuevo_DireccionComunidad' | i18next }}</mat-label>
                    <input matInput formControlName="direccionComunidad" maxlength="250">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--comunidad fin-->

            <!--contactos-->
            <div class="row">
              <div class="col-lg-12">
                <div class="subtitulo-s mb-3">
                  {{ 'Nuevo_DatosContacto' | i18next}}
                </div>
              </div>
              <div class="col-12" [hidden]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'">
                <div class="row">
                  <div class="col-lg-4 col-12">&nbsp;</div>
                  <div class="col-lg-8 col-12">
                    <label>{{ 'Nuevo_FormaEnvioCargo' | i18next }}</label><br>
                    <mat-radio-group formControlName="formaEnvioCargo" (change)="cambioFormaEnvioCargo()" class="row">
                      <mat-radio-button [value]="0" class="col-md-4 col-12">
                        {{ 'Nuevo_Correo' | i18next }}
                      </mat-radio-button>
                      <mat-radio-button [value]="1" class="col-md-4 col-12">
                        {{ 'Nuevo_Celular' | i18next }}
                      </mat-radio-button>
                      <mat-radio-button [value]="2" class="col-md-4 col-12">
                        {{ 'Nuevo_Fisicamente' | i18next }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <!-- #region Persona Natural -->
              <ng-container *ngIf="form.get(nombreControles.tipoRemitente)?.value === 'Natural'; else null">
                <!-- #region Persona de contacto -->
                <mat-form-field class="col-lg-4 col-12" appearance="outline">
                  <mat-label> {{ 'Nuevo_PersonaContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.contactoRemitenteNatural">
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Correo electrónico -->
                <mat-form-field class="col-12"
                  [class.col-md-4]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'"
                  [class.col-md-8]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'" appearance="outline"
                  *ngIf="form.get('formaEnvioCargo')?.value === 0">
                  <mat-label>{{ 'Nuevo_CorreoContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" *ngIf="form.get('formaEnvioCargo')?.value === 0"
                  [formControlName]="nombreControles.correoElectronicoRemitenteNatural">
                  <mat-error *ngIf="form.get('formaEnvioCargo')?.value === 0">
                    <app-error-validation-display [FormControl]="form.get(nombreControles.correoElectronicoRemitenteNatural)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Teléfono de contacto -->
                <mat-form-field class="col-lg-4" appearance="outline"
                  [hidden]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'">
                  <mat-label>{{ 'Nuevo_TelefonoContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.telefonoContactoRemitenteNatural">
                  <mat-icon matSuffix>phone</mat-icon>
                </mat-form-field>
                <!-- #endregion -->
              </ng-container>
              <!-- #endregion -->

              <!-- #region Persona Jurídica -->
              <ng-container *ngIf="form.get(nombreControles.tipoRemitente)?.value === 'Jurídica'; else null">
                <!-- #region Persona de contacto -->
                <mat-form-field class="col-lg-4 col-12" appearance="outline">
                  <mat-label>{{ 'Nuevo_PersonaContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.contactoRemitenteJuridica">
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Correo electrónico -->
                <mat-form-field class="col-12"
                  [class.col-md-4]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'"
                  [class.col-md-8]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'" appearance="outline"
                  *ngIf="form.get('formaEnvioCargo')?.value === 0">
                  <mat-label>{{ 'Nuevo_CorreoContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" *ngIf="form.get('formaEnvioCargo')?.value === 0" [formControlName]="nombreControles.correoElectronicoRemitenteJuridica">
                  <mat-error *ngIf="form.get('formaEnvioCargo')?.value === 0">
                    <app-error-validation-display [FormControl]="form.get(nombreControles.correoElectronicoRemitenteJuridica)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Teléfono de contacto -->
                <mat-form-field class="col-lg-4" appearance="outline"
                  [hidden]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'">
                  <mat-label>{{ 'Nuevo_TelefonoContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.telefonoContactoRemitenteJuridica">
                  <mat-icon matSuffix>phone</mat-icon>
                </mat-form-field>
                <!-- #endregion -->
              </ng-container>
              <!-- #endregion -->

              <!-- #region Comunidad -->
              <ng-container *ngIf="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'; else null">
                <!-- #region Persona de contacto -->
                <mat-form-field class="col-lg-4 col-12" appearance="outline">
                  <mat-label>{{ 'Nuevo_PersonaContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.contactoRemitenteComunidad">
                  <mat-error>
                    <app-error-validation-display [FormControl]="form.get(nombreControles.contactoRemitenteComunidad)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Correo electrónico -->
                <mat-form-field class="col-12"
                  [class.col-md-4]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'"
                  [class.col-md-8]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'" appearance="outline"
                  *ngIf="form.get('formaEnvioCargo')?.value === 0">
                  <mat-label>{{ 'Nuevo_CorreoContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" *ngIf="form.get('formaEnvioCargo')?.value === 0" [formControlName]="nombreControles.correoElectronicoRemitenteComunidad">
                  <mat-error *ngIf="form.get('formaEnvioCargo')?.value === 0">
                    <app-error-validation-display [FormControl]="form.get(nombreControles.correoElectronicoRemitenteComunidad)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Teléfono de contacto -->
                <mat-form-field class="col-12"
                  [class.col-md-4]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'"
                  [class.col-md-8]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'" appearance="outline"
                  *ngIf="form.get('formaEnvioCargo')?.value === 1">
                  <mat-label>{{ 'Nuevo_TelefonoContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="100" *ngIf="form.get('formaEnvioCargo')?.value === 1" [formControlName]="nombreControles.telefonoContactoRemitenteComunidad">
                  <mat-error *ngIf="form.get('formaEnvioCargo')?.value === 1">
                    <app-error-validation-display [FormControl]="form.get(nombreControles.telefonoContactoRemitenteComunidad)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>
                <!-- #endregion -->
                <!-- #region Dirección de contacto -->
                <mat-form-field class="col-12"
                  [class.col-md-4]="form.get(nombreControles.tipoRemitente)?.value !== 'Localidad'"
                  [class.col-md-8]="form.get(nombreControles.tipoRemitente)?.value === 'Localidad'" appearance="outline"
                  *ngIf="form.get('formaEnvioCargo')?.value === 2">
                  <mat-label>{{ 'Nuevo_DireccionContacto' | i18next}}</mat-label>
                  <input matInput autocomplete="off" maxlength="250" *ngIf="form.get('formaEnvioCargo')?.value === 2" [formControlName]="nombreControles.direccionContactoRemitenteComunidad">
                  <mat-error *ngIf="form.get('formaEnvioCargo')?.value === 2">
                    <app-error-validation-display [FormControl]="form.get(nombreControles.direccionContactoRemitenteComunidad)">
                    </app-error-validation-display>
                  </mat-error>
                </mat-form-field>
                <!-- #endregion -->
              </ng-container>
              <!-- #endregion -->

              <div class="col-lg-4"></div>
            </div>
            <!--contactos fin-->

          </mat-card>

        </div>
        <!--datos remitente-->

        <!--destinatario-->
        <div class="col-md-12 mb-3">

          <div class="subtitulo-p">{{ 'Nuevo_Destinatario' | i18next}}</div>

          <mat-card>
            <div class="row" [hidden]="form.get(nombreControles.noAplicaDestinatario)?.value || false">
              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_PersonaDestinataria' | i18next}}</mat-label>
                <input matInput [formControlName]="nombreControles.destinatario" [matAutocomplete]="autoDestinatario"
                  (keyup)="eventoFiltrarDestinatario()">
                <mat-autocomplete #autoDestinatario="matAutocomplete">
                  <mat-option *ngIf="IsLoadingAutoComplete">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </mat-option>
                  <ng-container *ngIf="!IsLoadingAutoComplete">
                    <mat-option (onSelectionChange)="eventoSeleccionarDestinatario(Item)"
                      *ngFor="let Item of ItemsFiltradosDestinatario" [value]="Item.Usuario.Name">
                      {{Item.Usuario.Name}} | {{Item.Usuario.Email}}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-icon matSuffix>account_box</mat-icon>
              </mat-form-field>
              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_Area' | i18next}} </mat-label>
                <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.area" readonly>
              </mat-form-field>
              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_Sede' | i18next}} </mat-label>
                <input matInput autocomplete="off" maxlength="100" [formControlName]="nombreControles.sede" readonly>
              </mat-form-field>
              <div class="col-lg-12">
                <mat-checkbox class="mt-3" (change)="onChangeNoAplicaDestinatario($event)"
                  [formControlName]="nombreControles.noAplicaDestinatario">
                  {{ 'Nuevo_DescripcionNoAplicaDestinatario' | i18next}}
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 col-12" appearance="outline">
                <mat-label>{{ 'Nuevo_Gerencia' | i18next}}</mat-label>
                <mat-select formControlName="idGerenciaSenior" (selectionChange)="cambioGerenciaSenior()">
                  <mat-option>{{ 'Nuevo_Seleccione' | i18next }}</mat-option>
                  <mat-option *ngFor="let item of ItemsGerenciaSenior" [value]="item.id">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card>

          <div class="subtitulo-p mt-3" [hidden]="!muestraSeccionDatosCominidadMVP">
            {{ 'Nuevo_DatosComunidad' | i18next}}
          </div>
          <mat-card [hidden]="!muestraSeccionDatosCominidadMVP">
            <div class="row">
              <mat-form-field class="col-md-4 col-12" appearance="outline">
                <mat-label>{{ 'Nuevo_Zona' | i18next}}</mat-label>
                <mat-select formControlName="idZona" (selectionChange)="cambioZona()">
                  <mat-option>{{ 'Nuevo_Seleccione' | i18next }}</mat-option>
                  <mat-option *ngFor="let item of ItemsZona" [value]="item.id">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-4 col-12" appearance="outline">
                <mat-label>{{ 'Nuevo_Localidad' | i18next}}</mat-label>
                <mat-select formControlName="idLocalidad" (selectionChange)="cambioLocalidad()">
                  <mat-option>{{ 'Nuevo_Seleccione' | i18next }}</mat-option>
                  <mat-option *ngFor="let item of ItemsLocalidad" [value]="item.id">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-4 col-12" appearance="outline">
                <mat-label>{{ 'Nuevo_Anexo' | i18next}}</mat-label>
                <mat-select formControlName="idAnexo">
                  <mat-option>{{ 'Nuevo_Seleccione' | i18next }}</mat-option>
                  <mat-option *ngFor="let item of ItemsAnexo" [value]="item.id">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card>
        </div>
        <!--destinatario-->

        <!--datos del documento-->
        <div class="col-md-12 mb-3">

          <div class="subtitulo-p">{{ 'Nuevo_DatosDocumento' | i18next}}</div>

          <mat-card>
            <div class="row">

              <div class="col-lg-12">
                <div class="alert alert-info" role="alert">
                  <ul>
                    <li>{{ 'Nuevo_DescripcionArchivoExtensiones' | i18next}} pdf, xls, xlsx, doc, docx, ppt, dwg, jpg,
                      png, mp4, mp3, rar y zip</li>
                    <li>{{ 'Nuevo_DescripcionArchivoPesoMaximo' | i18next}} </li>
                  </ul>
                </div>
              </div>

              <mat-form-field class="col-lg-12" appearance="outline">
                <mat-label>{{ 'Nuevo_Asunto' | i18next}}
                </mat-label>
                <textarea matInput autocomplete="off" maxlength="250"
                  [formControlName]="nombreControles.asunto"></textarea>
              </mat-form-field>

              <mat-form-field class="col-lg-4" appearance="outline">
                <mat-label>{{ 'Nuevo_Referencia' | i18next}}</mat-label>
                <input matInput autocomplete="off" maxlength="250" [formControlName]="nombreControles.referencia">
                <mat-icon [hidden]="language === 'en'"
                  matTooltip="Indique el número de documento al que hace referencia" matSuffix>info</mat-icon>
                <mat-icon [hidden]="!(language === 'en')" matTooltip="Indicate the document number to which it refers"
                  matSuffix>info</mat-icon>
              </mat-form-field>

              <div>
                <hr>
              </div>

              <div class="col-lg-12 text-end mb-4">
                <button [hidden]="language === 'en'" mat-icon-button
                  matTooltip="** Debe seleccionar el documento principal dirigido a la empresa (ej. Carta, Oficio)"
                  aria-label="">
                  <mat-icon>info</mat-icon>
                </button>
                <button [hidden]="!(language === 'en')" mat-icon-button
                  matTooltip="** You must select the main document addressed to the company (eg Letter, Official Letter)"
                  aria-label="">
                  <mat-icon>info</mat-icon>
                </button>

                <button mat-raised-button color="primary" (click)="FileControlDocumentosAdjuntos.click()">{{
                  'Nuevo_BotonAgregarAjduntos' | i18next}}</button>

                <input type="file" accept=".pdf,.xls,.xlsx,.doc,.docx,.ppt,.dwg,.jpg,.png,.mp4,.mp3,.rar,.zip"
                  #FileControlDocumentosAdjuntos [hidden]="true" multiple
                  (change)="OnFileUploadDocumentosAdjuntos($event)">

              </div>

              <div class="col-12 red-alert mb-3" *ngIf="mostrarAjuntoNoPdf && (
                form.get(nombreControles.tipoRemitente)?.value === 'Localidad'
                  ? form.get('formaEnvioCargo')?.value === 0
                  : true)">
                {{ 'Nuevo_AdjuntoNoPdf' | i18next }}
              </div>

              <div class="col-lg-12">
                <div class="row">
                  <ng-container *ngIf="ItemsAdjuntos && ItemsAdjuntos.length; else noItems">
                    <div class="col-12" *ngFor="let item of ItemsAdjuntos; let i = index">
                      <mat-divider *ngIf="i !== 0"></mat-divider>
                      <div class="row" [class.mt-2]="i !== 0">
                        <div class="col-12">
                          <div class="d-flex">
                            <div class="col">
                              <mat-radio-button [checked]="item.EsPrincipal"
                                (change)="onChangeAdjuntoEsPrincipal(item, $event)">
                                {{item.NombreArchivo}}
                              </mat-radio-button>
                            </div>
                            <span class="principal" *ngIf="item.EsPrincipal">{{ 'Nuevo_Principal' | i18next }}</span>
                            <button mat-icon-button aria-label="eliminar" (click)="OnEliminarAdjunto(item)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                        <mat-form-field class="col-md-6 col-12" appearance="outline">
                          <mat-label>
                            {{ 'Nuevo_Codigo' | i18next}}
                          </mat-label>
                          <input matInput autocomplete="off" maxlength="250" [value]="item.Codigo"
                            (change)="onChangeAdjuntoCodigo(item, $event)">
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-12" appearance="outline">
                          <mat-label>
                            {{ 'Nuevo_Tipo' | i18next}} *
                          </mat-label>
                          <mat-select [value]="item.Tipo">
                            <mat-option *ngFor="let value of ItemsTipoDocumento"
                              (click)="onChangeAdjuntoTipo(item, $event)" [value]="value.ID">{{ value.Title }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-12" appearance="outline">
                          <mat-label>{{ 'Nuevo_TituloDescripcion' | i18next}}</mat-label>
                          <textarea matInput [value]="item.Descripcion" maxlength="250" autocomplete="off"
                            (change)="onChangeAdjuntoDescripcion(item, $event)"></textarea>
                        </mat-form-field>
                        <label class="contador">{{item.Descripcion.length || 0}}/250</label>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #noItems>
                    <div class="col-12 text-center">
                      {{ 'Nuevo_DescripcionNoSeAgregaronAdjuntos' | i18next }}
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <!--datos del documento-->

        <!--notas-->
        <div class="col-12 mb-3">
          <div class="subtitulo-p">{{ 'Nuevo_Notas' | i18next}}</div>
          <mat-card>
            <app-notas #notas></app-notas>
          </mat-card>
        </div>
        <!--notas-->

        <!--captcha-->
        <div class="row d-flex justify-content-center mb-3 text-center">

          <div class="col-lg-12 mb-4 mt-4">
            <mat-checkbox class="example-margin" [formControlName]="nombreControles.autorizacion">
              {{autorizacionDatos}}
            </mat-checkbox>
          </div>

          <div class="d-flex flex-column col-lg-3" *ngIf="!ResetRecaptcha">
            <re-captcha class="g-recaptcha" (resolved)="RecaptchaResolved($event)" [siteKey]="Token"></re-captcha>
            <br>
            <span class="captcha-error" *ngIf="MostrarErrorRecaptcha">Debe marcar la casilla de verificación</span>
          </div>

          <div class="col-lg-12 mb-4 mt-4">
            <button mat-raised-button color="primary" (click)="onClickEnviar()">{{ 'Nuevo_BotonEnviar' |
              i18next}}</button>
          </div>

        </div>
        <!--captcha-->
      </form>

    </div>
  </mat-card>
  <div class="mt-4">
    <div class="col-lg-12 pb-3">
      <p class="color-grey">{{disclaimer}}</p>
    </div>
  </div>

</div>
