import { User } from "./../base/User";
import { ListItem } from "./../base/ListItem";
import { Constantes } from "./../../utils/Constantes";
import { Lookup } from "./../base/Lookup";
import { RestFiltros } from "../../utils/RestFiltros";
import { SPUser } from "./../base/SPUser";
import { SPParse } from "../../utils/SPParse";

export class EUsuario {
    ID: number;
    Usuario: SPUser;
    Sede: Lookup;
    Area: Lookup;
    Puesto: Lookup;

    constructor() {
        this.ID = 0;
        this.Usuario = new SPUser();
        this.Sede = new Lookup();
        this.Area = new Lookup();
        this.Puesto = new Lookup();
    }

    public static getColumnasSelect(): string[] {
        return [
            Constantes.columnas.ID,
            Constantes.columnas.Title,
            RestFiltros.obtenerFieldExpandUsuario(Constantes.columnas.Usuario),
            RestFiltros.obtenerFieldExpandLookup(Constantes.columnas.Sede),
            RestFiltros.obtenerFieldExpandLookup(Constantes.columnas.Area),
            RestFiltros.obtenerFieldExpandLookup(Constantes.columnas.Puesto),
        ];
    }

    public static getColumnasExpand(): string[] {
        return [Constantes.columnas.Usuario, Constantes.columnas.Sede, Constantes.columnas.Area, Constantes.columnas.Puesto];
    }

    public static parseJson(element: any): EUsuario {

        const objeto = new EUsuario();
        objeto.ID = SPParse.getNumber(element[Constantes.columnas.ID]);
        objeto.Usuario = SPUser.parseJson(element[Constantes.columnas.Usuario]);
        objeto.Sede = Lookup.parseJson(element[Constantes.columnas.Sede]);
        objeto.Area = Lookup.parseJson(element[Constantes.columnas.Area]);
        objeto.Puesto = Lookup.parseJson(element[Constantes.columnas.Puesto]);

        return objeto;
    }

    public static parseJsonBack(element: any): EUsuario {

        const objeto = new EUsuario();
        objeto.ID = SPParse.getNumber(element["id"]);
        objeto.Usuario = SPUser.parseJsonBack(element["usuario"]);
        objeto.Sede = Lookup.parseJsonBack(element["sede"]);
        objeto.Area = Lookup.parseJsonBack(element["area"]);
        objeto.Puesto = Lookup.parseJsonBack(element["puesto"]);

        return objeto;
    }
}
