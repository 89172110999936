<div class="row">
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>{{ 'Nuevo_NotasLabel' | i18next }}</mat-label>
    <textarea matInput [(ngModel)]="nota"></textarea>
  </mat-form-field>
  <div class="col-12">
    <button mat-raised-button color="primary" (click)="FileControlDocumentosAdjuntos.click()">
      {{ 'Nuevo_BotonAgregarAjduntos' | i18next }}
    </button>
    <input type="file" accept=".pdf,.xls,.xlsx,.doc,.docx,.ppt,.dwg,.jpg,.png,.mp4,.mp3,.rar,.zip"
      #FileControlDocumentosAdjuntos [hidden]="true" multiple (change)="fileUploadDocumentosAdjuntos($event)">
  </div>
  <div class="col-12 mt-3">
    <mat-chip-list>
      <mat-chip *ngFor="let item of adjuntos; let i = index" [removable]="true" (removed)="quitarArchivo(i)">
        {{item.nombre}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
