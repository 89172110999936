export const environment = {
    production: true,
    appPage: "/SitePages/App.aspx/",
    proxyTenant: "https://tgpweb.sharepoint.com",
    webRelativeUrl: "/sites/sgdtest",
    proxyUrl: "https://tgpweb.sharepoint.com/sites/sgdtest",
    clientId: "1f80800f-2958-454a-8d1a-43c4ca9f9a32",
    authority: "https://login.microsoftonline.com/6a9934b6-3b37-4b45-b3e9-26c04b9d50d1",
    redirectUri: "https://tgpweb.sharepoint.com/sites/sgdtest/",
    apiUrl: "https://app-tgp-mesapartes-be-qa-bs-01.azurewebsites.net/",
    apiAppId: "api://3b29e94e-242b-45df-bbb1-7194826acbc8/restApi.access",
    apiUrlBackEnd: "https://app-tgp-mesapartes-be-qa-bs-01.azurewebsites.net",
    recaptchaSiteKey: '6Lcy6v4oAAAAAD4pPOPRKVkBmcKVq1ba_SIQ9TRA',
    abreviaturaEmpresa: "TGP",
    urlLogo: "https://stgtgpqabs01.blob.core.windows.net/imagenes/logo-tgp.png",

    getRutaBase() {
      return `${environment.proxyUrl}`;
    },

    getRutaBaseApp() {
      let rutaBase = "";

      if (this.production) {
        rutaBase = environment.proxyTenant + environment.webRelativeUrl + environment.appPage;
      } else {
        rutaBase = environment.proxyTenant + "/";
      }

      return rutaBase;
    },
  };
