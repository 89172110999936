export class ERegistrarComunicacionExternaDto {
    IdPais: number;
    IdEmpresa: number;
    CodigoComunicacion: string;
    TipoComunicacion: string;
    TipoRemitente: string;
    IdComunidad?: number;
    IdGerenciaSenior?: number;
    NombreComunidad?: string;
    DireccionComunidad?: string;

    IdRemitenteExterno: number;
    IdTipoDocumentoRemitente: number;
    NumeroDocumentoRemitente: string;
    NombreRemitente: string;
    ApellidoPaternoRemitente: string;
    ApellidoMaternoRemitente: string;
    IdPaisRemitente: number;
    RucRemitente: string;
    RazonSocialRemitente: string;
    NombrePersonaContacto: string;
    CorreoPersonaContacto: string;
    TelefonoPersonaContacto: string;
    DireccionPersonaContacto?: string;
    FormaEnvioCargo?: number;

    IdZona?: number;
    IdLocalidad?: number;
    IdAnexo?: number;

    NoAplicaDestinatario: boolean;
    IdDestinatarioInterno: number;
    IdDestinatarioInternoSharePoint: number;
    NombreUsuarioDestino: string;
    CorreoUsuarioDestino: string;
    IdAreaUsuarioDestino: number;
    IdSedeUsuarioDestino: number;
    IdPuestoUsuarioDestino: number;

    Asunto: string;
    Referencia: string;

    IdTipoDocumentoPrincipal: number;
    NumeroDocumentoPrincipal: string;
    TituloDocumentoPrincipal: string;

    AdjuntoComunicacion: any[];
    IdEstado: number;
    AbreviaturaEmpresa: string;
    recaptchaToken: string;

    constructor() {
        this.IdPais = 0;
        this.IdEmpresa = 0;
        this.CodigoComunicacion = '';
        this.TipoComunicacion = '';
        this.TipoRemitente = '';

        this.IdRemitenteExterno = 0;
        this.IdTipoDocumentoRemitente = 0;
        this.NumeroDocumentoRemitente = '';
        this.NombreRemitente = '';
        this.ApellidoPaternoRemitente = '';
        this.ApellidoMaternoRemitente = '';
        this.IdPaisRemitente = 0;
        this.RucRemitente = '';
        this.RazonSocialRemitente = '';
        this.NombrePersonaContacto = '';
        this.CorreoPersonaContacto = '';
        this.TelefonoPersonaContacto = '';

        this.NoAplicaDestinatario = false;
        this.IdDestinatarioInterno = 0;
        this.IdDestinatarioInternoSharePoint = 0;
        this.NombreUsuarioDestino = "";
        this.CorreoUsuarioDestino = "";
        this.IdAreaUsuarioDestino = 0;
        this.IdSedeUsuarioDestino = 0;
        this.IdPuestoUsuarioDestino = 0;

        this.Asunto = '';
        this.Referencia = '';

        this.IdTipoDocumentoPrincipal = 0;
        this.NumeroDocumentoPrincipal = '';
        this.TituloDocumentoPrincipal = '';

        this.AdjuntoComunicacion = [];
        this.IdEstado = 0;
        this.AbreviaturaEmpresa = "";
        this.recaptchaToken = "";
    }
}
