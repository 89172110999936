import { Injectable } from "@angular/core";
import { sp } from "@pnp/sp";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";
import { environment } from "src/environments/environment";
import { Constantes } from "../../shared/utils/Constantes";
import { MasterService } from "../../shared/services/master.service";
import { EMaestro } from "../../shared/models/entidades/EMaestro";
import { ClienteApiHttpService } from '../../shared/services/cliente-api-http.service';
import { EParametrosGenerales } from "src/app/shared/models/entidades/EParametrosGenerales";
import { ECaja } from "src/app/shared/models/entidades/ECaja";

@Injectable({
  providedIn: "root"
})
export class MaestroDocumentoInternoService {

  private uriObtenerRemitenteListar: string = environment.apiUrlBackEnd + '/api/administracion/listar-entidades-externas';
  private uriBuscarRemitente: string = environment.apiUrlBackEnd + '/api/administracion/buscar-entidad-externa';
  private uriRegistrarRemitente: string = environment.apiUrlBackEnd + '/api/administracion/registrar-entidad-externa';
  private uriObtenerEntidadExternaPorTipoListar: string = environment.apiUrlBackEnd + '/api/administracion/listar-entidades-externas-tipo';
  private uriObtenerMaestros: string = environment.apiUrlBackEnd + '/api/administracion/listar-maestros';
  private uriObtenerConfiguracion: string = environment.apiUrlBackEnd + '/api/administracion/obtener-configuracion';
  private uriObtenerLocalidades: string = environment.apiUrlBackEnd + '/api/administracion/listar-localidades';
  private uriObtenerLocalidadesPorZona: string = environment.apiUrlBackEnd + '/api/administracion/listar-localidad-por-zona';
  private uriObtenerAnexosPorLocalidad: string = environment.apiUrlBackEnd + '/api/administracion/listar-anexo-por-localidad';
  private uriObtenerGerenciasSenior: string = environment.apiUrlBackEnd + '/api/administracion/listar-gerencia-senior';
  private uriFiltrarMaestroUsuarios: string = environment.apiUrlBackEnd + '/api/administracion/filtrar-maestro-usuarios';
  private uriFiltrarMaestroTipoDocumentos: string = environment.apiUrlBackEnd + '/api/administracion/listar-tipos-documentos';
  private uriObtenerEmpresa: string = environment.apiUrlBackEnd + '/api/administracion/obtener-empresa';

  constructor(public masterService: MasterService, private clienteApiHtppService: ClienteApiHttpService) {
    sp.setup({
      sp: {
        baseUrl: `${environment.proxyUrl}`
      }
    });
  }

  async getItemsAdmMaestroBase(lista: string): Promise<EMaestro[]> {
    let ItemsMaestro: EMaestro[] = [];

    const selectFields = EMaestro.getColumnasSelect();
    const filtroFields = 'Habilitado eq ' + 1;

    let query = sp.web.lists.getByTitle(lista).items.select(...selectFields).filter(filtroFields);

    let results = await query.top(4999).getPaged().then((p: any) => {
      return p;
    });

    if (results.results.length > 0) {
      for (const item of results.results) {
        ItemsMaestro.push(EMaestro.parseJson(item));
      }
    }

    return ItemsMaestro;
  }

  public obtenerRemitenteListar(body: any): Promise<any> {

    if (body.TextoBusqueda === '') { return new Promise(res => res([])); }

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerRemitenteListar, body).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public buscarRemitente(body: any): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriBuscarRemitente, body).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public registrarRemitente(body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriRegistrarRemitente, body).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );

    });
  }

  async getItemsAdmParametrosGenerales(title: string): Promise<EParametrosGenerales[]> {
    const ItemsParametros: EParametrosGenerales[] = [];

    const selectFields = EParametrosGenerales.getColumnasSelect();
    const filtroFields = "Habilitado eq 1 and Title eq '" + title + "'";

    const query = sp.web.lists.getByTitle(Constantes.listas.AdmParametrosGenerales).items.select(...selectFields).filter(filtroFields);

    const results = await query.top(4999).getPaged().then((p: any) => {
      return p;
    });

    if (results.results.length > 0) {
      for (const item of results.results) {
        ItemsParametros.push(EParametrosGenerales.parseJson(item));
      }
    }

    return ItemsParametros;
  }

  public obtenerEntidadExternaPorTipoListar(body: any): Promise<any> {

    if (body.TextoBusqueda === '') { return new Promise(res => res([])); }

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerEntidadExternaPorTipoListar, body).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public obtenerMaestros(lista: string, tipo: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerMaestros + "?lista=" + lista + "&tipo=" + tipo).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public obtenerConfiguracion(title: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerConfiguracion + "?title=" + title).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public obtenerLocalidadesPorZona(idZona: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(`${this.uriObtenerLocalidadesPorZona}?idZona=${idZona}`).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public obtenerAnexosPorLocalidad(idLocalidad: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(`${this.uriObtenerAnexosPorLocalidad}?idLocalidad=${idLocalidad}`).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public obtenerGerenciasSenior(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerGerenciasSenior).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public filtrarMaestroUsuarios(valor: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriFiltrarMaestroUsuarios + "?valor=" + valor).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public filtrarMaestroTipoDocumentos(): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriFiltrarMaestroTipoDocumentos).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }

  public obtenerEmpresa(abreviatura: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerEmpresa + "?idEmpresa=" + abreviatura).subscribe(
        (res: any) => resolve(res),
        (error: any) => reject(error)
      );
    });
  }


}
