import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private empresaStorageId: string = '42d93aef-6c11-4a54-bb51-247fd07a6dba';

  constructor() { }

  public get IdEmpresa(): number | null {
    const storageValue = localStorage.getItem(this.empresaStorageId);
    if (storageValue) {
      const idString = atob(storageValue);
      return parseInt(idString);
    }
    return null;
  }

  public set IdEmpresa(value: number | null) {
    if (value === null) {
      return;
    }
    const idString = value.toString();
    const serialized = btoa(idString);
    localStorage.setItem(this.empresaStorageId, serialized);
  }

}
