import { NgxSpinnerService } from 'ngx-spinner';
import { ComunicacionService } from 'src/app/shared/services/comunicacionService.service';
import { Component, OnInit } from '@angular/core';
import { RegistrarNotaComunicacionExterna } from 'src/app/shared/models/entidades/RegistrarNotaComunicacionExterna';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.css']
})
export class NotasComponent implements OnInit {

  public nota: string | undefined;
  public adjuntos: {
    file: File,
    nombre: string
  }[] = [];
  public notasAgregadas: {
    nota: string,
    archivo: {
      file: File,
      nombre: string
    }
  }[] = [];

  constructor(
    private spinnerService: NgxSpinnerService,
    private comunicacionService: ComunicacionService
  ) { }

  ngOnInit(): void {
  }

  public quitarArchivo(indice: number): void {
    this.adjuntos.splice(indice, 1);
  }

  public fileUploadDocumentosAdjuntos(event: any): void {
    if (event.target.files.length <= 0) {
      return;
    }
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const archivo = files[i] as File;
      const existe = this.adjuntos.find(x => x.nombre === archivo.name);
      if (!!existe) {
        continue;
      }
      this.adjuntos.push({
        file: archivo,
        nombre: archivo.name
      });
    }
  }

  public validarValor(): boolean {
    if (this.adjuntos.length > 0 && (this.nota?.trim() || '').length <= 0) {
      return false;
    }
    return true;
  }

  public guardarValor(
    idComunicacion: string,
    correoContacto: string
  ): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!this.nota || this.nota.trim().length <= 0) {
        resolve(false);
        return;
      }
      this.spinnerService.show();
      const item = {
        adjuntosFile: this.adjuntos.map(x => x.file),
        comentarios: this.nota,
        correoContacto: correoContacto,
        idComunicacion: idComunicacion
      } as RegistrarNotaComunicacionExterna;
      this.comunicacionService.registrarNota(item)
        .then(res => {
          this.spinnerService.hide();
          resolve(res);
        }).catch(ex => {
          this.spinnerService.hide();
          resolve(false);
        });
    });
  }
}
