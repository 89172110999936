import { Lookup } from "../models/base/Lookup";
import { User } from "../models/base/User";
import { Constantes } from "./Constantes";
import { DatePipe } from "@angular/common";
import { Funciones } from "./Funciones";

export class SPParse {
  static getBool(valor: any): boolean {
    if (valor) {
      return valor;
    }

    return false;
  }

  static getString(valor: any): string {
    if (valor) {
      return valor.toString();
    }

    return "";
  }

  static getStringExtractCharacter(valor: any): string {
    if (valor) {
      return valor
        .replace(new RegExp("<i#>", "g"), "-")
        .replace(new RegExp("</i#>", "g"), "");
    }

    return "";
  }

  static getStringInsertHTML(valor: any): string {
    if (valor) {
      let texto = valor
        .replace(
          new RegExp("<i#>", "g"),
          '<div class="desc-comp"><div><i class="material-icons">check_circle_outline</i></div><div>'
        )
        .replace(new RegExp("</i#>", "g"), "</div></div>")
        .replace(/\n/g, "<br>");
      texto = texto.replace(new RegExp("<br><br>", "g"), "<br>");
      return texto;
    }

    return "";
  }

  static getStringInsertPointHTML(valor: any): string {
    if (valor) {
      //let texto = valor.replace(new RegExp('<i#>', 'g'), '<div class="desc-comp"><div><i class="material-icons ico-point">trip_origin</i></div><div>').replace(new RegExp('</i#>', 'g'), '</div></div>').replace(/\n/g, '<br>');
      let texto = valor
        .replace(
          new RegExp("<i#>", "g"),
          '<div class="desc-comp"><div><i class="material-icons ico-point">horizontal_rule</i></div><div>'
        )
        .replace(new RegExp("</i#>", "g"), "</div></div>")
        .replace(/\n/g, "<br>");

      texto = texto.replace(new RegExp("<br><br>", "g"), "<br>");
      return texto;
    }

    return "";
  }

  static getNumber(valor: any): number {
    if (valor) {
      return parseInt(valor, 10);
    }

    return 0;
  }

  static getFloat(valor: any): number {
    if (valor) {
      return parseFloat(valor);
    }

    return 0;
  }

  static getDate(valor: any): Date | null {
    if (valor) {
      let fecha = new Date(valor);
      fecha = new Date(fecha);
      return fecha;
    }

    return null;
  }

  static getDateLarge(valor: any): string | null {
    if (valor) {
      let fecha = new Date(valor);
      const datePipe = new DatePipe("es-PE");
      return datePipe.transform(fecha, "dd/MM/yyyy");
    }

    return "";
  }

  static getDateReporte(valor: any): Date | null {
    if (valor) {
      let fecha = new Date(valor);
      fecha = new Date(fecha.setMinutes(30));
      return fecha;
    }

    return null;
  }

  static getHyperlink(valor: any): any {
    let datos = { Descripcion: "", URL: "" };

    if (valor) {
      datos.Descripcion = valor[Constantes.columnas.Descripcion];
      datos.URL = valor[Constantes.columnas.Url];
    }

    return datos;
  }

  static getStringHtml(valor: any): any {
    if (valor) {
      // return $(valor).
      // return valor.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      return valor.replace(/<[^>]*>/g, "");

      // return str.replace(new RegExp(stringToFind, 'gi'), stringToReplace);

      // return valor.replace('<.*?>', '');
    }

    return "";
  }

  static getLookup(objeto: any) {
    const lookup = { ID: "0", Title: "" } as Lookup;
    if (!Funciones.esUndefinedNullOrEmpty(objeto)) {
      lookup.ID = objeto[0].lookupId.toString();
      lookup.Title = objeto[0].lookupValue;
      return lookup;
    } else {
      return lookup;
    }
  }  

  static getUser(objeto: any) {
    const user = { ID: 0, Title: "", Email: "" } as User;
    if (!Funciones.esUndefinedNullOrEmpty(objeto)) {
      user.ID = objeto[0].id;
      user.Title = objeto[0].title;
      user.Email = objeto[0].email;
      return user;
    } else {
      return user;
    }
  }

  static convertirStringToDate(valor: any): Date {
    const parts = valor.split("/");

    if (parts.length === 3) {
      const mes = parseInt(parts[1], 10) - 1;
      return new Date(parts[2], mes, parts[0]);
    }
    return new Date();
  }

  static getEntidadLookup(objeto: any) {
    const lookup = { ID: '0', Title: '' } as Lookup;
    if (!Funciones.esUndefinedNullOrEmpty(objeto)) {
      lookup.ID = objeto.ID.toString();
      lookup.Title = objeto.Title;
      return lookup;
    } else {
      return lookup;
    }
  }

}