import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NuevoRegistroComponent } from './components/mesa-partes/nuevo-registro/nuevo-registro.component';
import { ExitoComponent } from './components/mesa-partes/exito/exito.component';
import { ErrorComponent } from './components/mesa-partes/error/error.component';

const routes: Routes = [
  {
    path: '',
    component: NuevoRegistroComponent,
    pathMatch: 'full',
  },
  {
    path: 'nuevo-registro',
    component: NuevoRegistroComponent
  },
  {
    path: 'exito',
    component: ExitoComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
