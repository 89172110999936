import { Lookup } from "src/app/shared/models/base/Lookup";
import { DatePipe } from "@angular/common";
import { User } from "../models/base/User";

export class Constantes {
  static readonly path = {
    home: "",
    msal: "msal",
    bandejaContratos: "contratos/bandeja",
    nuevoContrato: "contratos/nuevo",
    editarContrato: "contratos/editar/:idcontrato"
  };

  static readonly listas = {
    AdmUsuario: "AdmUsuario",
    AdmRol: "AdmRol",
    AdmPerfiles: "AdmPerfiles",
    Logs: "Logs",
    AdmTipoDocumento: 'AdmTipoDocumento',
    AdmTipoDocGestion: 'AdmTipoDocGestion',
    AdmTipoAlmacenamiento: 'AdmTipoAlmacenamiento',
    AdmSede: 'AdmSede',
    AdmArea: 'AdmArea',
    AdmPuesto: 'AdmPuesto',
    AdmMedioRecepcion: 'AdmMedioRecepcion',
    AdmEstadoDocumentoInterno: 'AdmEstadoDocumentoInterno',
    AdmPais: 'AdmPais',
    AdmTema: 'AdmTema',
    AdmProyecto: 'AdmProyecto',
    AdmParametrosGenerales: 'AdmParametrosGenerales',
    AdmZona: 'AdmZona',
    AdmLugaresArchivo: 'AdmLugaresArchivo',
    AdmContenedoresEstantes: 'AdmContenedoresEstantes',
    AdmVolumenes: 'AdmVolumenes',
    AdmCajas: 'AdmCajas',
    AdmOpcionesModulo: 'AdmOpcionesModulo',
    Contratos: 'Contratos'
  };

  static readonly columnas = {
    Id: "Id",
    ID: "ID",
    Title: "Title",
    EMail: "EMail",
    Modified: "Modified",
    Descripcion: "Descripcion",
    DescripcionError: "DescripcionError",
    Modulo: "Modulo",
    State: "State",
    Usuario: "Usuario",
    Sede: "Sede",
    Area: "Area",
    Puesto: "Puesto",
    AreaDestino: "AreaDestino",
    Habilitado: "Habilitado",
    Url: "Url",
    TipoPersona: 'TipoPersona',
    Longitud: 'Longitud',
    Valor: 'Valor',
    SedeArchivo: 'SedeArchivo',
    Ubicacion: 'Ubicacion',
    Estante: 'Estante',
    Caja: 'Caja',

    Codigo: "Codigo",
    CodigoExpediente: "CodigoExpediente",
    TipoDocumentoRemitente: "TipoDocumentoRemitente",
    NumeroDocumentoRemitente: "NumeroDocumentoRemitente",
    NombreRemitente: "NombreRemitente",
    ApellidoPaternoRemitente: "ApellidoPaternoRemitente",
    ApellidoMaternoRemitente: "ApellidoMaternoRemitente",
    RUC: "RUC",
    RazonSocialRemitente: "RazonSocialRemitente",
    CorreoRemitente: "CorreoRemitente",
    TelefonoRemitente: "TelefonoRemitente",
    UsuarioDestino: "UsuarioDestino",
    Asunto: "Asunto",
    Referencia: "Referencia",
    MedioRecepcion: "MedioRecepcion",
    TipoDocumentoPrincipal: "TipoDocumentoPrincipal",
    NumeroDocumentoPrincipal: "NumeroDocumentoPrincipal",
    TituloDocumentoPrincipal: "TituloDocumentoPrincipal",
    Titulo: "Titulo",
    Nota: "Nota",

    OpcionesModulo: "OpcionesModulo",

    FechaRegistro: "FechaRegistro",
    FechaModificacion: "FechaModificacion",
    FechaLimiteAtencion: "FechaLimiteAtencion",
    Tarea: "Tarea",
    Estado: "Estado",
  };

  static readonly mensajesPagina = {
  };

  static readonly moduloSistema = {
    Contratos: "Contratos"
  };

  static readonly valoresGenericos = {
    /*DESARROLLO*/
    pathSitio: "/sites/tgpsgddev",
    urlSitio: "https://avancestec.sharepoint.com/sites/tgpsgddev",
    urlServicio: "",
  };

  static readonly headers = {
    "Content-Type": "application/json"
  }

  static readonly tipoParametros = {
    TipoAlmacenamiento: "Tipo de almacenamiento",
    TipoDocumento: "Tipo de documento",
    TipoDocumentoIdentidad: "Tipo de documento identidad",
  }
}