import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormularioBase } from 'src/app/shared/pages/FormularioBase';
import { MasterService } from 'src/app/shared/services/master.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent extends FormularioBase implements OnInit {

  mensajeError: any = "";

  constructor(
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public utilService: UtilService,
    public masterService: MasterService
  ) {
    super('exito', dialog, route, router, spinner, utilService, masterService);
  }

  ngOnInit(): void {
    this.InicializarControles();
  }

  InicializarControles() {
    this.mensajeError = sessionStorage.getItem('SesionRegistroPublico_MensajeError');
    sessionStorage.setItem('SesionRegistroPublico_MensajeError', "");
  }

}
