import { SessionStorageService } from './../../../shared/services/session-storage.service';
import { MaestroDocumentoInternoService } from './../../../shared/services/maestro-documento-interno.service';
import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormularioBase } from 'src/app/shared/pages/FormularioBase';
import { MasterService } from 'src/app/shared/services/master.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { environment } from 'src/environments/environment';;
import { Funciones } from 'src/app/shared/utils/Funciones';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-exito',
  templateUrl: './exito.component.html',
  styleUrls: ['./exito.component.css']
})
export class ExitoComponent extends FormularioBase implements OnInit {

  urlLogo: string = "";
  language: string = "es";
  codigoComunicacion: any = "";
  modoEnvio: number = 0;

  constructor(
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public utilService: UtilService,
    public masterService: MasterService,
    private maestroDocumentoInternoService: MaestroDocumentoInternoService,
    private sessionStorageService: SessionStorageService
  ) {
    super('exito', dialog, route, router, spinner, utilService, masterService);
  }

  async ngOnInit(): Promise<void> {
    debugger;
    const params = await this.ObtenerParametros();
    const idEmpresa = this.sessionStorageService.IdEmpresa;
    this.modoEnvio = parseInt(params['envio']);
    const empresa = await this.maestroDocumentoInternoService.obtenerEmpresa(idEmpresa!.toString());
    this.InicializarControles(empresa.data.urlLogo);
  }

  private async ObtenerParametros(): Promise<Params> {
    return new Promise<Params>(resolve => {
      this.route.queryParams.subscribe(res => {
        resolve(res);
      });
    });
  }

  InicializarControles(urlLogo: string) {
    this.urlLogo = urlLogo;
    this.codigoComunicacion = sessionStorage.getItem('SesionRegistroPublico_CodigoComunicion');
    sessionStorage.setItem('SesionRegistroPublico_CodigoComunicion', "");
  }

  obtenerParametro(parametro: string): any {
    const valor = this.route.snapshot.queryParams[parametro];

    if (!valor) {
      return 0;
    }

    return valor;
  }

}
